.header__search-complete-holder {
  display: none;
  position: absolute;
  top: 101%;
  right: 0;
  width: 100%;
  background-color: white;
}

.header__search-all {
  display: none;
  background-color: $brand-grey-lighter;
  padding: 12px 15px;
  text-decoration: none;
  position: relative;

  @include mq(laptop-s) {
    padding: 20px 15px;
  }

  span {
    color: $brand-gold;
    font-size: 14px;
    line-height: 18px;
  }

  .header__search-all-arrow {
    position: absolute;
    right: 30px;
    top: 50%;
    height: 31px;
    width: 5px;
    transform: translateY(-50%) rotate(-90deg);
  }
}

.autoComplete_list {
  width: 100%;
  list-style: none;
  padding: 0;
  margin: 0;
  background-color: white;
}

.autoComplete_result,
.no_result {
  min-height: 80px;
  border-bottom: 1px solid $brand-grey-light;
  padding: 10px;
  font-size: 14px;
  line-height: 18px;

  transition: background-color 0.3s;
  color: $brand-black;
  cursor: pointer;

  &:hover {
    background-color: $brand-grey-lighter;
  }
}

.autoComplete_highlighted {
  @include font-weight(bold);
}

.header__search-auto {
  max-height: 24vh;
  overflow-y: auto;

  @include mq(laptop-s) {
    max-height: calc(100vh - 140px);
  }

  .suggested-link {
    text-decoration: none;
    min-height: 50px;
    border-bottom: 1px solid $brand-grey-light;
    padding: 10px;
    font-size: 14px;
    line-height: 18px;

    transition: background-color 0.3s;
    color: $brand-black;
    cursor: pointer;
    display: block;

    &:hover {
      background-color: $brand-grey-lighter;
    }
  }
}

.search-tag {
  display: inline-block;
  margin-right: 5px;
  color: $brand-copper;
  font-size: 12px;
  line-height: 14px;
}

.search-code {
  display: inline-block;
  margin-right: 5px;
  font-size: 12px;
  line-height: 14px;
}

.search-new {
  @include font-weight(bold);
  padding: 3px;
  display: inline-block;
  background-color: $brand-gold;
  color: $brand-white;
  font-size: 12px;
  line-height: 14px;
}
