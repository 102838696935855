// sass-mq configuration - please see https://github.com/sass-mq/sass-mq for more infos
// Enable media queries support
$mq-responsive: true;

// Base font size
$mq-base-font-size: 16px !default;

//Definies gutter list
$mq-gutters: (
    small: 24px,
    medium: 32px,
    large: 40px,
    xlarge: 60px
);

// Defines breakpoints list
$mq-breakpoints: (
    mobile: 375px,
    mobile-m: 430px,
    mobile-l: 550px,
    tablet: 768px,
    laptop-s: 1024px,
    laptop-l: 1200px,
    desktop-s: 1440px,
    desktop-m: 1920px,
    desktop-l: 2500px,
    search-overlay-l: 1500px,
    search-overlay-m: 1300px,
    video-play: 814px
);

@import "~sass-mq/mq";

// Padding on either side of the main container.
$site-wide-padding: map-get($mq-gutters, "large");

// Width of the main container.
$desktop-width: map-get($mq-breakpoints, "desktop-m");
$site-width: $desktop-width + ($site-wide-padding * 2);
