.sub-nav {
  height: 54px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  background-color: $brand-grey-lighter;
  position: sticky;
  top: 0;
  z-index: 3;
  overflow: hidden;
  transform: translateY(0);
  transition: transform 0.4s ease-in-out;

  &.is-open {
    height: auto;
  }

  @include mq(laptop-s) {
    height: auto;
    flex-direction: row;
    justify-content: center;
    padding-top: 34px;
    padding-bottom: 34px;
  }

  a,
  button {
    @include font-weight(book);

    color: rgba($brand-black, 0.5);
    font-size: rem(12px);
    margin-left: 10px;
    margin-right: 10px;
    text-decoration: none;

    transition: all 0.4s ease-in-out;

    @include mq($until: laptop-s) {
      width: calc(100% - 20px);
      text-align: center;
      padding-top: 20px;
      padding-bottom: 20px;

      &.is-scrolled {
        order: -1;
      }
    }

    &.is-current,
    &.is-scrolled {
      @include font-weight(bold);
      color: rgba($brand-black, 1);
    }

    &:hover {
      color: rgba($brand-black, 1);
      cursor: pointer;
    }
  }

  .sub-nav__button {
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    transform: translateX(101%);
    transition: all 0.4s ease-in-out;

    @include mq($until: laptop-s) {
      display: none;
    }

    > div {
      display: block;
      height: 100%;
    }

    a,
    button {
      display: flex;
      align-content: center;
      align-items: center;
      color: $brand-white;
      height: 100%;
      padding-left: 62px;
      padding-right: 40px;
      margin-left: 0;
      margin-right: 0;

      svg {
        transform: translateY(2px);
      }
    }
  }
}
