.hero {
    background-size: 70%;
    background-repeat: no-repeat;
    background-position: center right;

    @include mq ( $until: laptop-l ) {
      background-image: none !important;
    }

    &__title {
      position: relative;
      text-indent: 0;
    }

    &__text {
      padding-left: 20px;
      padding-right: 20px;

      @include mq ( $until: laptop-l ) {
        padding-top: 40px;
      }

      @include mq ( tablet ) {
        padding-left: 32px;
        padding-right: 32px;
      }

        max-width: 1150px;
        padding-top: 150px;
        padding-left: 150px;
        color: $brand-black;

        p {
          max-width: 355px;
        }
    }
}