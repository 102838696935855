.installers {
  .installer {
    background-color: $brand-grey-lighter;
    padding: 31px;

    &__details {
      @include font-weight(bold);

      display: flex;
      justify-content: space-between;

      @include mq(tablet) {
        margin-bottom: 30px;
      }

      .installer__contact-item {
        color: $brand-black;
        font-size: 14px;
        margin-top: 15px;
        margin-bottom: 15px;
        background-repeat: no-repeat;
        word-break: break-all;

        &--title {
          font-size: 20px;
          margin-top: 0;
          margin-bottom: 30px;
        }

        &--icon {
          width: 15px;
          height: 10px;
          display: inline-block;
          background-repeat: no-repeat;
          background-position: center center;
          margin-right: 5px;

          @include mq(tablet) {
            width: 31px;
            height: 21px;
            margin-right: 22px;
            transform: translateY(5px);
          }
        }

        &--phone {
          background-image: url("~img/svg/svg--phone.svg");
        }

        &--distance {
          background-image: url("~img/svg/svg--pin.svg");
        }

        &--website {
          background-image: url("~img/svg/svg--link.svg");
        }

        &--email {
          background-image: url("~img/svg/svg--mail.svg");
        }
      }

      ul {
        list-style: none;
        -webkit-padding-start: 0;
      }

      a {
        text-decoration: none;
        color: $brand-black;
      }

      .installer__image {
        display: none;
        @include mq(tablet) {
          display: block;
          max-width: 141px;
        }
      }
    }
  }
}
