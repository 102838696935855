
.scroll-panel {
    padding-bottom: 20px;
    margin: 0 -10px;

    .simplebar-track {
        background-color: $brand-grey-lighter;
    }

    .simplebar-track.simplebar-horizontal {
        pointer-events: auto;
        height: 5px;
    }

    .simplebar-track.simplebar-horizontal .simplebar-scrollbar {
        top: 0;
        cursor: pointer;
        height: 5px;
    }

    .simplebar-scrollbar:before {
        background-color: $brand-gold;
        border-radius: 0;
    }
}

.scroll-panel__inner {
    display: flex;

    &.scroll-panel__inner--center {
        justify-content: center;
    }
}

.scroll-panel__item {
    width: 50%;
    flex-shrink: 0;
    padding: 0 10px;

    @include mq ( laptop-s ) {
        width: 33%;
        padding: 0 20px;
    }

    @include mq ( desktop-s ) {
        width: 25%;
    }

    .card {
        margin: 0 auto;
    }
}