.rect-grid {
  max-width: 1390px;
  list-style: none;
  margin: 0 auto;
  padding-inline-start: 0;
  overflow: hidden;
  display: grid;
  grid-gap: 10px;
  justify-content: center;

  &--margin {
    margin-bottom: 150px;
  }

  @media only screen and (orientation: landscape) and (max-width: 768px) {
    grid-template-columns: repeat(6, 1fr);
    padding-bottom: 12%;

    .rect {
      &:nth-of-type(5n + 4) {
        grid-column-start: 2;
      }
    }
  }

  @media only screen and (orientation: portrait) and (max-width: 767px) {
    /* 2-1  rectangles per row */
    grid-template-columns: repeat(4, 1fr);
    padding-bottom: 14%;

    .rect:nth-of-type(3n + 3) {
      grid-column-start: 2;
    }
  }

  @include mq($from: tablet, $until: laptop-s) {
    /* 3-2  rectangles per row */
    grid-template-columns: repeat(6, 1fr);
    padding-bottom: 12%;

    .rect {
      &:nth-of-type(5n + 4) {
        grid-column-start: 2;
      }
    }
  }

  @include mq($from: laptop-s, $until: laptop-l) {
    /* 4-3  rectangles per row */
    grid-template-columns: repeat(8, 1fr);
    padding-bottom: 7%;

    .rect:nth-child(7n + 5) {
      grid-column-start: 2;
    }
  }

  @include mq(laptop-l) {
    /* 5-4  rectangles per row */
    grid-template-columns: repeat(10, 1fr);
    padding-bottom: 7%;

    .rect:nth-child(9n + 6) {
      grid-column-start: 2;
    }

    &--alt {
      /* 4-3  rectangles per row */
      grid-template-columns: repeat(8, 1fr);

      .rect:nth-child(7n + 5) {
        grid-column-start: 2;
      }

      .rect:nth-child(9n + 6) {
        grid-column-start: inherit;
      }
    }
  }

  .rect {
    &--empty {
      display: none;
      transform: translate3d(0, 0, 0);

      @include mq(laptop-s) {
        display: block;
      }
    }
  }
}

.rect {
  position: relative;
  visibility: hidden;
  outline: 1px solid transparent; /* fix for jagged edges in FF on hover transition */
  grid-column-end: span 2;

  &:after {
    content: "";
    display: block;
    padding-bottom: 86.602%; /* =  100 / tan(60) * 1.5 */
  }
}

.rect-title {
  position: absolute;
  z-index: 2;
  top: 66.66%;
  left: 20px;
  right: 20px;
  visibility: visible;
  pointer-events: none;

  font-size: rem(14px);
  text-align: center;
  color: $brand-white;
  transform: translateY(-50%);
}

.rect-inner {
  width: 100%;
  position: absolute;
  z-index: 1;
  padding-bottom: percentage(2/3); // 3:2
  overflow: hidden;
  visibility: hidden;
  outline: 1px solid transparent; /* fix for jagged edges in FF on hover transition */

  & * {
    position: absolute;
    visibility: visible;
    outline: 1px solid transparent; /* fix for jagged edges in FF on hover transition */
  }
}

.rect-link {
  width: 100%;
  height: 100%;
  text-align: center;
  color: $brand-white;
  overflow: hidden;
  display: grid;
  justify-content: center;
  align-items: center;

  .rect-image {
    width: 100%;
    height: 100%;
    margin: 0 auto;
    transform: rotate(0deg);
    background-position: center center;
    background-size: cover;
    transition: all 1s ease-in-out;

    &:before {
      width: 100%;
      height: 100%;
      background-color: rgba($brand-black, 0.3);
      content: "";
      position: absolute;
      top: 0;
      left: 0;
    }

    &--no-tint {
      &:before {
        display: none;
      }
    }

    &--color-gold,
    &--color-grey,
    &--color-copper {
      background-color: $brand-gold;

      &:before {
        width: 80%;
        height: 80%;
        left: 50%;
        top: 50%;
        opacity: 0.2;
        transform: translate(-50%, -50%);
        content: "";
        display: block;
        background-image: url("~img/svg/svg--logo.svg");
        background-color: transparent;
        position: absolute;
        background-repeat: no-repeat;
        background-position: center center;
      }
    }

    &--color-grey {
      background-color: $brand-grey-darkest;
    }

    &--color-copper {
      background-color: $brand-copper;
    }

    &--color-grey-light {
      background-color: $brand-grey-lighter;
    }

    &.image-slide {
      &--primary {
        position: absolute;
        left: 0;
        transition: left 0.3s ease-in-out;
        .rect:hover & {
          left: -100%;
        }
      }

      &--secondary {
        position: absolute;
        left: 100%;
        transition: left 0.3s ease-in-out;
        .rect:hover & {
          left: 0;
        }
      }
    }
  }
}
