/**
 * Add z-index values in this file.
 *
 */

$layer-root: -1;
$layer-default: 10;
$layer-god: 100;

$layer-overlay: 1000;
$layer-header: 2000;
$layer-popup: 3000;
