.section-title {
  display: flex;
  flex-direction: column;
  text-align: center;
  padding-top: rem(33px);
  padding-bottom: rem(33px);

  @include mq(tablet) {
    padding-top: rem(66px);
    padding-bottom: rem(66px);

    &--less-padding {
      padding-bottom: rem(40px);
    }

    &--no-padding {
      padding-bottom: 0px;
    }
  }

  h1,
  h2,
  h3 {
    order: 2;
    color: $brand-heading;
  }

  h4 {
    order: 1;
    color: $brand-grey-havwoods;
    margin-bottom: 10px;
  }

  &--white {
    h1,
    h2,
    h3,
    h4 {
      color: $brand-white;
    }
  }

  &--faq-title {
    display: flex;
    flex-direction: column;
    text-align: center;
    padding-top: rem(33px);
    padding-bottom: rem(33px);
    h3 {
      order: 2;
      color: $brand-heading;
    }
  }
}
