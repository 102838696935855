.news-popup {
    width: 75%;
    max-width: 474px;
    position: fixed;
    bottom: 0;
    left: 0;
    background-color: $brand-white;
    z-index: 4;
    border-top: 1px solid $brand-grey-lighter;
    border-right: 1px solid $brand-grey-lighter;
    opacity: 0;
    transition: opacity .4s ease-in-out;

    &.is-active {
        opacity: 1;
        display: block;
    }

    &.is-hidden {
        display: none;
    }

    .grid-container {
        text-decoration: none;
        padding: 10px;
    }

    .close-icon {
        position: absolute;
        top: 20px;
        right: 15px;
    }

    &__image {
        display: none;
        padding-top: 90%;
        background-size: cover;
        background-position: center center;

        @include mq ( tablet ) {
            display: block;
        }
    }

    &__text {
        &.grid-column {
            display: flex;
            justify-content: space-around;
            flex-direction: column;
            padding: 35px 20px 35px 5px;
        }
    }

    h4 {    
        @include font-weight(bold);
        
        color: $brand-black;
        line-height: 25px;
        margin-bottom: 29px;
    }

    .btn {
        padding-left: 30px;
        padding-right: 30px;
    }
}