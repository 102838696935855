.account {
    padding: 48px;

    h1 {
        color: $brand-black;
        text-align: center;
        margin-bottom: 20px;
    }

    h3 {
        color: $brand-black;
        text-align: center;
    }

    h4 {
        font-size: 10px;
        color: $brand-red;
        margin-top: 22px;
        margin-bottom: 20px;
    }

    .small {
        text-align: center;
        padding-left: 20px;
        padding-right: 20px;
        margin-top: 35px;
        border-bottom: 1px solid rgba($brand-black, 0.1);
        padding-bottom: 17px;

        a {
            text-decoration: none;
            color: $brand-black;
        }
    }

    .next {
        width: 100%;
        display: block;
        background-color: $brand-black;
        text-align: center;
        padding: 12px;
        margin-top: 25px;
        color: $brand-white;
        text-decoration: none;
    }

    .my-account-love {
        width: 39px;
        height: 39px;
        background-color: $brand-pink;
        position: absolute;
        border-radius: 39px;
        display: inline-block;
        left: 3px;
        transform: translateY(-10px);
        opacity: 0.4;
        transition: opacity 0.4s ease-in-out;

        svg {
            width: 22px;
            height: 20px;
            fill: transparent;
            stroke: $brand-white;
            position: absolute;
            top: 12px;
            left: 9px;
        }

        @include mq($until: laptop-s) {
            left: 50%;
            transform: translateX(-50%);
        }
    }
}

.my-account {
    display: flex;
    flex-direction: column;
    align-items: center;

    &__details {
        @include font-weight(bold);

        width: 100%;
        display: block;
        margin-top: 10px;
        margin-bottom: 10px;
        color: $brand-black;
        font-size: 14px;

        @include mq(mobile-l) {
            display: flex;
        }
    }

    &__item {
        width: 100%;
        display: flex;
        align-items: center;

        @include mq(mobile-l) {
            width: 50%;
            padding-right: 15px;
        }

        @include mq(tablet) {
            padding-left: 20%;
        }

        @include mq(laptop-s) {
            padding-left: 30%;
        }
    }

    &__value {
        width: 100%;
        color: rgba($brand-black, 0.3);
        word-break: break-all;
        margin-top: 5px;

        @include mq(mobile-l) {
            width: 50%;
            margin-top: 0;
        }

        .form-input--selectbox {
            max-width: 209px;
        }
    }
}

.update-details {
    width: 50%;
    align-self: flex-end;
    margin-top: 50px;

    .arrow-btn {
        margin-left: 0;
    }
}

.manage-account-content {
    .update-details {
        margin-left: auto;
    }
}

.sample-history {
    .sample-data {
        padding-left: 15px;

        @include mq(tablet) {
            display: flex;
        }

        &__title {
            @include font-weight(bold);

            margin-bottom: 40px;
            display: none;

            &:before {
                display: none !important;
            }

            @include mq(tablet) {
                display: block;
            }

            &.sample-data__item--info {
            }
        }

        &__item {
            width: 100%;
            color: $brand-black;
            font-size: 14px;

            &:before {
                @include font-weight(bold);

                width: 100%;
                display: block;

                margin-top: 15px;
                margin-bottom: 5px;

                @include mq(tablet) {
                    display: none;
                }
            }

            &--order-numb {
                &:before {
                    content: "Order #";
                    margin-top: 0;
                }

                @include mq(tablet) {
                    width: 26%;
                }
            }

            &--order-date {
                &:before {
                    content: "Order date";
                }

                @include mq(tablet) {
                    width: 19%;
                }
            }

            &--quantity {
                &:before {
                    content: "Quantity";
                }

                @include mq(tablet) {
                    width: 19%;
                }
            }

            &--info {
                padding-right: 20px;
                text-transform: none;

                &:before {
                    content: "Project info";
                }

                @include mq(tablet) {
                    width: 40%;
                    padding-right: 80px;
                }

                @include mq(desktop-s) {
                    padding-right: 70px;
                }
            }
        }
    }
}
