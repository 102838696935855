.form-container {
  &--sort-by {
    display: flex;
    justify-content: center;
    flex-direction: column;

    @include mq(mobile) {
      flex-direction: column-reverse;
      align-items: center;
    }

    @include mq(laptop-s) {
      margin-top: -50px;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-end;
      transform: translateY(-100%);
    }

    .filter-product-range & {
      justify-content: flex-end;
    }

    .filter-accessory & {
      transform: none;
      margin-top: 50px;

      .sort-by-container {
        margin-bottom: 0;
        margin-left: auto;
      }
    }
  }

  &--account {
    max-width: 311px;
    margin: 40px auto 0;

    .form-input {
      @include font-weight(bold);

      width: 100%;
      display: block;
      margin-bottom: 14px;
      color: $brand-black;
      font-size: rem(14px);
      padding-top: 14px;
      padding-bottom: 14px;

      &--selectbox {
      }

      &--submit {
        background-color: $brand-gold;
        color: $brand-white;
        margin-top: 25px;
        border: 0;
      }

      &::placeholder {
        color: $brand-black;
        opacity: 1;
        font-family: $base-font-family;
      }
    }
  }

  &--installer {
    width: 100%;
    max-width: 546px;
    background-color: $brand-black;
    border: 1px solid $brand-white;
    padding: 32px;
    display: flex;
    justify-content: space-between;

    .form-input {
      width: 70%;
      color: $brand-black;

      &--search {
        width: 25%;
        background-color: $brand-gold;
        color: $brand-white;
        border: 0;
        transition: all 0.4s ease-in-out;

        &:hover {
          background-color: $brand-white;
          color: $brand-gold;
        }
      }

      &::placeholder {
        color: $brand-black;
        opacity: 1;
        font-family: $base-font-family;
      }
    }
  }

  &--samples {
    max-width: 760px;
    margin: 0 auto;
    padding-left: 30px;
    padding-right: 30px;

    @include mq(tablet) {
      padding-left: 100px;
      padding-right: 100px;
    }

    .privacy-policy {
      @include font-weight(bold);

      font-size: rem(10px);
      text-decoration: none;
      color: rgba($brand-black, 0.6);
      margin-top: 33px;
      text-align: center;
      display: block;
    }

    .form-titles {
      margin-top: 40px;
      margin-bottom: 40px;

      @include mq($until: mobile-l) {
        display: block;
      }

      + .field-container {
        .form__label {
          margin-top: 0;
        }
      }

      h3 {
        color: $brand-black;
        margin-right: 20px;

        @include mq($until: mobile-l) {
          margin-bottom: 10px;
        }
      }

      h4 {
        font-size: rem(12px);
        color: $brand-red;
      }

      &--small {
        margin-bottom: 0;
      }

      &--alt {
        @include mq($until: laptop-l) {
          margin-bottom: 10px;
        }
      }
    }

    .form-label {
      margin-top: 16px;
    }

    .form-input {
      color: $brand-black;
      padding-left: 10px;
      padding-right: 10px;

      &--search,
      &--order {
        background-color: $brand-black;
        color: $brand-white;
      }

      &--order {
        width: 100%;
        max-width: 240px;
        margin-top: 60px;
        left: 50%;
        transform: translateX(-50%);
        position: relative;

        &__progress {
          cursor: progress;
        }
      }

      &::placeholder {
        color: $brand-black;
        opacity: 1;
        font-family: $base-font-family;
      }
    }
  }
}

.form-field {
  margin-bottom: 20px;

  .explanation-text {
    padding-top: 5px;
    font-size: 0.8em;
    max-width: 75%;
  }
}

.control-label,
.form-label {
  @include font-weight(bold);

  color: $brand-black;
  font-size: rem(12px);
  margin-bottom: 7px;
  display: block;
}

.form-footer {
  p {
    text-align: center;
    margin-top: 28px;
    color: $brand-black;

    a {
      @include font-weight(bold);

      text-decoration: none;
      color: $brand-black;
    }
  }

  &--login {
    margin-top: 80px;

    p {
      margin-top: 0px;
      text-align: left;
    }

    .forgotten {
      @include font-weight(book);
    }
  }
}

textarea.form-control,
.form-control,
.form-input {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 18px;
  padding-right: 18px;
  border: 1px solid $brand-grey-light;
  color: $brand-grey-havwoods;
  font-size: rem(14px);
  appearance: none;
  outline: 0;

  &--title {
    width: 100%;
    max-width: 270px;
  }

  &--full-width {
    width: 100%;
  }

  &--order,
  &--search,
  &--submit {
    cursor: pointer;
  }

  .ktc-radio & {
    appearance: radio;
  }

  .ktc-checkbox & {
    appearance: checkbox;
    width: 90px;
  }
}

.ktc-checkbox {
  display: flex;
  label {
    width: 100%;
    font-size: 0.875rem;
    align-self: flex-start;
  }
  .form-control {
    width: 30px;
  }
}

.form-validation-error {
  color: $brand-red;
  font-size: rem(10px);
  transform: translateY(3px);
  display: none;

  &.has-error {
    display: block;
  }
  &.form-validation-error-space {
    padding-bottom: 8px;
  }
}

.form-control {
  width: 75%;
}

input[type="radio"].form-control {
  width: 5%;
}

textarea.form-control,
.form-input--textarea {
  width: 100%;
  height: 155px;
  resize: none;
  margin-bottom: 40px;
  font-family: $base-font-family;

  & + .form-validation-error {
    transform: translateY(-37px);
  }
}

textarea.form-control {
  margin-bottom: 0;
}

select.form-control,
.form-input--selectbox {
  font-family: $base-font-family;
  padding-right: 48px;
  background-image: url("~img/svg/svg--chevron.svg");
  background-size: 11px 6px;
  background-repeat: no-repeat;
  background-position: calc(100% - 18px) center;
  order: 1;

  @include mq(laptop-s) {
    order: 2;
    margin-right: 5px;
  }

  &-small {
    width: 100%;

    @include mq(mobile-l) {
      width: 40%;
    }
  }

  &-medium {
    width: 100%;

    @include mq(mobile-l) {
      width: 60%;
    }
  }

  &-full {
    width: 100%;
  }
}

.form-checkbox {
  @include font-weight(bold);

  color: rgba($brand-black, 0.5);
  font-size: rem(10px);
  text-align: left;
  margin-top: 25px;

  label {
    cursor: pointer;
    margin-left: rem(12px);
    display: inline-block;

    &:before {
      content: "";
      width: 16px;
      height: 16px;
      border: 2px solid rgba($brand-black, 0.3);
      display: inline-block;
      margin-right: 12px;
      transition: border-color 0.4s ease-in-out;
      transform: translateY(5px);
    }

    &:hover:before {
      border-color: $brand-grey-dark;
    }
  }

  @at-root #{&}.is-selected label:before {
    border-color: $brand-copper;
    background-image: url("~img/svg/svg--tick.svg");
    background-position: center center;
    background-repeat: no-repeat;
  }
}

.form-titles {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.form-search-postcode {
  .form-input {
    width: 60%;

    &--search {
      width: 35%;
    }
  }
}

.form-search-postcode-links {
  margin-top: 16px;
  margin-bottom: 16px;
}

.form-contact-details {
  display: block;
  justify-content: space-between;
  align-items: flex-start;

  @include mq(mobile-l) {
    display: flex;
  }

  &__item {
    width: 100%;

    @include mq(mobile-l) {
      width: 35%;

      &:nth-of-type(2) {
        width: 60%;
      }
    }

    .form-input {
      width: 100%;
    }
  }
}

select::-ms-expand {
  display: none;
}

@include mq($until: laptop-s) {
  .fake-dropdown {
    width: 100%;
    height: 50px;
    display: block;
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;

    &:before {
      width: 20px;
      height: 20px;
      background-image: url("~img/svg/svg--chevron.svg");
      background-repeat: no-repeat;
      background-position: center center;
      content: "";
      position: absolute;
      top: 15px;
      right: 15px;
      transition: all 0.4s ease-in-out;
    }

    &--subnav {
      height: 54px;

      &:before {
        top: 18px;
        right: 20px;
      }
    }

    &.is-open {
      &:before {
        transform: rotate(180deg);
      }
    }
  }
}

.ktc-form-wrapper .ktc-form:not(&.ktc-form-modal-content) {
  .ktc-checkbox,
  .ktc-radio {
    .form-control {
      @include netc-sr-only;
    }

    label {
      padding-top: 10px;
      display: block;
      cursor: pointer;
    }

    input[type="checkbox"] + input[type="hidden"] + label,
    input[type="radio"] + label {
      &:before {
        content: "";
        flex-shrink: 0;
        background: $brand-white;
        border-radius: 100%;
        border: 1px solid $brand-grey-light;
        display: inline-block;
        width: 23px;
        height: 23px;
        position: relative;
        vertical-align: top;
        cursor: pointer;
        text-align: center;
        transition: box-shadow 0.25s ease;
        margin-right: 15px;
        margin-top: -6px;

        @include mq(tablet) {
          width: 31px;
          height: 31px;
        }
      }
    }

    input[type="checkbox"] + input[type="hidden"] + label {
      &:before {
        border-radius: 0;
      }
    }

    input[type="checkbox"]:checked + input[type="hidden"] + label,
    input[type="radio"]:checked + label {
      &:before {
        background-color: $brand-black;
        box-shadow: inset 0 0 0 4px $brand-white;

        @include mq(tablet) {
          box-shadow: inset 0 0 0 6px $brand-white;
        }
      }
    }
  }
}

.ktc-radio-list,
.ktc-checkbox-list {
  .ktc-radio,
  .ktc-checkbox {
    margin-bottom: 15px;
    display: flex;
  }
}

.custom-select {
  position: relative;
  margin: 0 15px 0 0;
  flex: 1 1 auto;
  display: inline-block;
  max-width: calc(100% - 110px);

  @include mq(tablet) {
    width: 262px;
  }

  @include mq(laptop-s) {
    margin: 0 30px 0 60px;
  }
}

.custom-select__current {
  width: 100%;
  height: 40px;
  color: $brand-white;
  border: 1px solid $brand-white;
  background-color: $brand-grey-havwoods;
  font-size: 11px;
  line-height: 14px;
  padding: 6px 35px 6px 50px;
  position: relative;
  text-align: left;
  transition: background-color 0.3s;
  cursor: pointer;

  @include mq(tablet) {
    font-size: 12px;
  }

  &:hover {
    background-color: $brand-grey-dark;
  }

  .flag-icon {
    position: absolute;
    left: 15px;
    top: 50%;
    transform: translateY(-50%);
    width: 19px;
    height: 19px;

    img {
      width: 19px;
      height: 19px;
    }
  }

  .custom-select__chevron {
    width: 14px;
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);

    img {
      width: 14px;
      height: 4px;
      transform: scaleY(-1);
      transition: transform 0.3s;

      .is-active & {
        transform: scaleY(1);
      }
    }
  }
}

.custom-select__text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  width: 100%;
}

.custom-select__dropdown {
  width: 100%;
  list-style: none;
  padding: 0 15px 15px;
  margin: 0;
  position: absolute;
  left: 0;
  top: 100%;
  background-color: $brand-grey-havwoods;
  border: 1px solid $brand-white;
  border-top: none;
  text-align: left;
  display: none;

  li {
    list-style-image: url(data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7);
  }

  .is-active & {
    display: block;
  }
}

.custom-select__link {
  position: relative;
  color: $brand-white;
  text-decoration: none;
  display: block;
  padding: 17px 0;
  border-bottom: 1px solid rgba($brand-white, 0.1);

  &:hover {
    text-decoration: underline;
  }

  .flag-icon {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);

    img {
      width: 19px;
      height: 19px;
      object-fit: contain;
    }
  }
}

.custom-select__content {
  @include font-weight(bold);

  padding-left: 35px;
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  width: 100%;
}

.clearance-toggle {
  display: flex;
  align-items: center;

  &__label {
    margin-left: 20px;
    font-size: 14px;
  }

  &__switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
  }

  &__checkbox {
    opacity: 0;
    width: 0;
    height: 0;

    &:checked {
      + .clearance-toggle__slider {
        background-color: $brand-gold;

        &::before {
          transform: translateX(26px);
        }
      }
    }
  }

  &__slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $brand-grey-dark;
    transition: 0.4s;
    border-radius: 34px;

    &::before {
      position: absolute;
      content: "";
      height: 26px;
      width: 26px;
      left: 4px;
      bottom: 4px;
      background-color: $brand-white;
      transition: 0.4s;
      border-radius: 50%;
    }
  }
}

.sort-by-container {
  @include mq(mobile) {
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
  }
}

.field-container {
  &--search {
    margin-bottom: 10px;
    position: relative;
    display: flex;
    justify-content: center;
    margin: 4.375rem;

    @include mq($until: tablet) {
      margin: 2.375rem 0;
      margin-left: 2rem;
    }
  }
}

.form-cta-holder {
  display: block;
  text-align: center;
}

.form-cta {
  display: flex;
  flex-direction: column;

  & + .form-cta {
    margin-left: 0;
    margin-top: 10px;
  }
}
