.accordion {
    &__item {
        cursor: pointer;

        &.is-open {
            .accordion__item-content {
                height: auto;
            }
        }

        &-title {
            a {
                color: currentColor;
                text-decoration: none;
            }
        }
    }

    &__item-content {
        height: 0;
        overflow: hidden;

        :last-child {
            margin-bottom: 0;
        }
    }

    &--product-filters {
        text-align: center;

        .accordion__item {
            border-bottom: 1px solid rgba($brand-black, 0.1);

            &.is-open {
                .accordion__item-title {
                    &:before {
                        transform: rotate(180deg);
                        right: rem(15px);
                        margin-top: rem(-2px);
                    }
                }

                .accordion__item-content {
                    padding: rem(10px);
                    padding-bottom: rem(19px);
                }
            }
        }

        .accordion__item-title {
            @include font-weight(bold);

            color: $brand-black;
            padding-top: rem(19px);
            padding-bottom: rem(19px);

            position: relative;

            &:before {
                width: rem(11px);
                height: rem(6px);
                display: block;
                content: "";
                position: absolute;
                background-image: url("~img/svg/svg--chevron.svg");
                background-size: 11px 6px;
                background-repeat: no-repeat;
                top: 50%;
                right: rem(10px);
                transform: translate(-50%, -50%);
                transition: all 0.4s ease-in-out;
            }
        }
    }

    &--faqs,
    &--samples {
        .accordion__item {
            border-bottom: 1px solid rgba($brand-black, 0.1);

            &.is-open {
                .accordion__item-title {
                    &:before {
                        background-image: url("~img/svg/svg--minus.svg");
                    }
                }

                .accordion__item-content {
                    padding-bottom: rem(19px);
                }
            }
        }

        .accordion__item-title {
            color: $brand-black;
            padding: rem(19px 50px 19px 0);

            position: relative;

            &:not(.accordion__item-has-link) {
                &:before {
                    width: rem(20px);
                    height: rem(20px);
                    display: block;
                    content: "";
                    position: absolute;
                    background-image: url("~img/svg/svg--plus.svg");
                    background-size: rem(20px 20px);
                    background-repeat: no-repeat;
                    top: 50%;
                    right: rem(25px);
                    transform: translateY(-50%);
                    transition: all 0.4s ease-in-out;

                    @include mq(desktop-s) {
                        right: rem(10px);
                    }
                }
            }
        }

        .accordion__item-content {
            padding-top: 0;
            padding-left: 0;
            padding-right: rem(45px);
        }
    }

    &--samples {
        .accordion__item-title {
            &:before {
                top: 0%;
                transform: translate(-50%, 20px);

                @include mq(desktop-s) {
                    top: 50%;
                    right: rem(10px);
                    transform: translate(-50%, -50%);
                }
            }
        }
    }

    &--faqs {
        &:last-child {
            margin-bottom: rem(100px);
        }

        .accordion__item {
            padding-left: rem(15px);

            &:first-child {
                border-top: 1px solid rgba($brand-black, 0.1);
            }
        }

        .accordion__item-title {
            @include font-weight(bold);
        }
    }
}
