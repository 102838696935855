.quick-nav {
  position: fixed;
  bottom: 15px;
  right: gutter-width("small");
  transform: translate(50%, 0);
  z-index: 10;
  transition: all 0.4s ease-in-out;

  .quick-nav-outer & {
    top: auto;
    bottom: 80px;
    transform: translate(50%, 0);
  }

  .off-screen + main .quick-nav-outer & {
    top: 50%;
    bottom: auto;
    transform: translate(50%, -50%);
  }

  &.is-visible {
    @include mq($until: tablet) {
      bottom: 0;
      position: absolute;
      top: auto;
      transform: translate(50%, 0);
    }
  }

  @media screen and (orientation: landscape), screen and (max-height: 540px) {
    display: flex;
  }

  @include mq(tablet) {
    right: gutter-width("medium");

    @media screen and (min-height: 540px) {
      top: 50%;
      bottom: auto;
      transform: translate(50%, -50%);
      display: block;
    }
  }

  @include mq(laptop-s) {
    right: gutter-width("large");
  }

  @include mq(desktop-s) {
    right: gutter-width("xlarge");
  }

  &__item {
    width: 60px;
    height: 60px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 6px;
    margin-bottom: 6px;
    text-decoration: none;
    border-radius: 50%;
    background-color: #bdbcaf;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    transition: background-color 200ms ease-in-out;

    &:hover {
      background-color: #322b2a;
    }

    @media screen and (orientation: landscape), screen and (max-height: 540px) {
      margin-left: 6px;
      margin-right: 6px;
    }

    @include mq(tablet) {
      @media screen and (min-height: 540px) {
        margin-left: 0;
        margin-right: 0;
      }
    }

    .hex-icon {
      z-index: 3;
    }

    .svg--inner {
      fill: $brand-white;
      z-index: 4;
    }

    &--love {
      .svg--inner {
        width: 18px;
        height: 19px;
        fill: $brand-pink;
        transform: translateY(2px);
      }
    }

    &--compare {
      display: none;

      @include mq(tablet) {
        display: flex;
      }

      .svg--inner {
        width: 17px;
        height: 24px;
      }
    }

    &--sample {
      .svg--inner {
        width: 25px;
        height: auto;
        fill: $brand-white;
        transform: translateX(-2px);
      }

      &:hover {
        fill: $brand-white;
      }
    }

    &--phone {
      .svg--inner {
        width: 14px;
        height: 14px;
      }
    }

    &--logout {
      .svg--inner {
        width: 21px;
        height: 18px;
        transform: translateX(-1px);
      }
    }
  }

  &__counter {
    width: 18px;
    height: 18px;
    position: absolute;
    top: -4px;
    right: 0px;
    z-index: 4;
    justify-content: center;
    align-items: center;
    display: none;

    &.has-items {
      display: flex;
    }

    span {
      font-size: rem(10px);
      z-index: 4;
      color: $brand-white;
    }

    .hex-icon {
      width: 18px;
      height: 18px;
      z-index: 3;
    }
  }
}
