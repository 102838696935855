.banner {
  min-height: 625px;
  position: relative;

  @include mq ( tablet ) {
    height: 100vh; 
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
  }
}

.banner__container {
  position: absolute;
  top: 0;
  max-width: 410px;
  width: 100%;
  height: 100%;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  padding-left: 20px;
  padding-right: 20px;
  
  @include mq ( laptop-s ) {
    max-width: 600px;
    top: 90px;
  }
}

.banner__inner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.banner__titles {
  color: $brand-white;
  margin-bottom: 30px;

  h1, h4 {
    font-family: HKNova, sans-serif;
    font-style: normal;
    font-weight: 500;
  }

  h1 {
    font-size: rem(24px);
  }
}

.banner__btn-group {
  display: flex;
  flex-direction: column;
  max-width: 350px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;

  .btn {
    flex-shrink: 0;
    margin-bottom: 20px;
    font-size: 12px;

    @include mq ( $until: tablet ) {
      padding-left: 12px;
      padding-right: 12px;
    }
  }
}