.tag-list {
  display: flex;
  justify-content: space-around;
  align-content: center;
  align-items: center;
  margin-bottom: 20px;
  flex-wrap: wrap;

  &--center {
    justify-content: center;
  }

  &__item {
    color: rgba($brand-black, 0.3);
    margin-left: 5px;
    margin-right: 5px;
    margin-bottom: 10px;
    font-size: 10px;

    padding: 4px 9px;
    border: 1px solid rgba($brand-black, 0.1);
    border-radius: 2px;
    text-decoration: none;
    transition: all 0.4s ease-in-out;

    &:hover {
      color: rgba($brand-black, 1);
      border: 1px solid rgba($brand-black, 1);
    }
  }
}
