.listing {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  overflow: hidden;

  &.grid-container {
    display: grid;
    grid-column-gap: 10px;
  }

  .card {

    .hex {
      @include mq($until: mobile-m) {
        padding-bottom: 20%;
      }

      padding-bottom: 30%;
    }
  }

  .grid-column {
    margin: 0 auto;

    &.card--post {
      width: 100%;
      padding-top: 20px;
      padding-bottom: 20px;
      padding-left: 10px;
      padding-right: 10px;
    }
  }
}
