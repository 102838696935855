.quick-add {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;

  &-inner {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
  }

  &__item {
    height: 39px;
    min-width: 39px;
    border-radius: 39px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    margin-left: 4px;
    margin-right: 4px;
    text-decoration: none;
    position: relative;
    transition: background-color 0.4s ease-in-out;
    outline: 0;

    &:before {
      width: 39px;
      height: 39px;
      border-radius: 39px;
      position: absolute;
      content: '';
      transform: scale(0);
      top: 0;
      left: 0;
      transition: all 0.4s ease-in-out;
      z-index: -1;
    }

    &--visualize {
      visibility: visible !important;
    }

    @keyframes animation-name {
      0% {
        transform: scale(1);
      }
      100% {
        transform: scale(1.5);
        opacity: 0;
      }
    }

    &.quick-add__item--compare {
      display: none;

      @include mq(tablet) {
        display: flex;
      }

      &:before {
        background-color: $brand-gold;
      }

      &:hover {
        .svg {
          fill: $brand-gold;
          stroke: none;
        }
      }
    }

    &.quick-add__item--love {
      &:hover {
        .svg {
          fill: $brand-pink;
          stroke: $brand-pink;
        }
      }

      &:before {
        background-color: $brand-pink;
      }
    }

    &.quick-add__item--sample {
      stroke: transparent;
      fill: $brand-white;
      transition: transform 0.4s ease-in-out;
      margin-top: 5px;
      font-size: 0.75em;

      &:hover {
        .svg {
          fill: $brand-green;
          stroke: $brand-green;
        }
      }

      &:before {
        background-color: $brand-green;
      }

      &:after {
        width: auto;
        height: auto;
        display: block;
        content: attr(data-add);
        opacity: 1;
        text-transform: none;
        color: $brand-black;
        transition: all 0.4s ease-in-out;
        margin-left: 3px;
        margin-right: 8px;
      }
    }

    &.is-added {
      &:before {
        animation: animation-name 1s ease forwards;
      }

      &.quick-add__item--love {
        background-color: $brand-pink;

        &:hover {
          .svg {
            stroke: $brand-black;
          }
        }

        .svg {
          transform: translateY(2px);
        }
      }

      &.quick-add__item--sample {
        background-color: $brand-green;
        transform: translateX(0);

        &:after {
          content: attr(data-remove);
          color: $brand-white;
        }

        &:hover {
          .svg {
            stroke: $brand-black;
          }

          &:after {
            color: $brand-black;
          }
        }
      }

      &.quick-add__item--compare {
        background-color: $brand-gold;

        .svg {
          fill: $brand-white;
          stroke: none;
          transform: translate(1px, -1px);
        }

        &:hover {
          .svg {
            fill: $brand-black;
          }
        }
      }

      &.quick-add__item--compare .roomvo-stimr {
        background-color: $brand-gold;

        .svg {
          fill: $brand-white;
          stroke: none;
          transform: translate(1px, -1px);
        }

        &:hover {
          .svg {
            fill: $brand-black;
          }
        }
      }

      .svg {
        fill: none;
        stroke: $brand-white;
      }
    }
  }

  .svg {
    fill: transparent;
    stroke: $brand-grey-darkest;
    transition: all 0.4s ease-in-out;
  }

  .svg--heart {
    width: 20px;
    height: 20px;
    stroke-width: 1.5px;
    transform: translateY(2px);
  }

  .svg--sample {
    width: 26px;
    height: 26px;
  }

  .svg--compare {
    width: 26px;
    height: 26px;
    fill: $brand-grey-darkest;
    stroke: none;
  }

  .svg--visualise {
    width: 26px;
    height: 26px;
    fill: $brand-grey-darkest;
    stroke: none;
  }
}
