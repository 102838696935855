.product-compare {
    margin-top: 50px;
    overflow-x: hidden;

    &__container {
        padding-left: 100px;
        padding-right: 150px;
    }

    &__title {
        @include font-weight(bold);

        text-align: center;

        color: $brand-black;
        font-size: 20px;
        margin-bottom: 44px;

        &--no-padding {
            margin-bottom: 0;
        }
    }

    .compare-row {
        width: 100%;
        border-top: 1px solid rgba($brand-black, 0.1);

        &--no-border {
            border: 0;
        }
    }

    .compare-column {
        @include font-weight(bold);

        width: 100%;
        font-size: 14px;
        display: flex;

        &__data {
            width: 100%;
            max-width: 400px;
            padding: 25px 0;
            color: rgba($brand-black, 0.3);

            &--title {
                color: rgba($brand-black, 1);
                font-size: 16px;
            }

            .fake-break {
                display: block;
                height: 40px;
            }
        }
    }
}
