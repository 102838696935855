.video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;

    &.playing {
        & + .video__play {
            opacity: 0;
        }
    }

    &__play {
        width: 80px;
        height: 80px;
        position: absolute;
        transform: translateY(-8px);        
        transition: opacity .4s ease-in-out;


        .svg {
            width: 28px;
            height: 28px;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            position: absolute;
            
            &--inner {
                fill: $brand-white;
                margin-left: 3px;
            }

            &.hex-icon {
                width: 80px;
                height: 80px;
            }
        }

        @include mq ( video-play ) {
            transform: translateY(-20px);
        }
    }
}