.async-content {
  opacity: 1 !important;
}

// Breakpoint settings
@import 'settings/settings-grid';

// libs
@import '~sass-rem/rem';

// functions
@import 'helpers/functions';

// helpers
@import 'helpers/mixins';
@import 'helpers/list-inputs';

// @import-normalize;

// settings
@import 'settings/settings-brand';
@import 'settings/settings-layers';
@import 'settings/settings-type';
@import 'settings/settings-global';

// tools
@import '~@netc/core/src/sass/core/core-mixins';
@import '~tiny-slider/src/tiny-slider';
@import '~simplebar/dist/simplebar.css';
@import '~tippy.js/dist/tippy.css';
@import '~glightbox/dist/css/glightbox';
@import '~toastify-js/src/toastify.css';

// helpers
@import 'helpers/helpers';

// components - alphabetical order please 😀
@import 'components/component-accordion';
@import 'components/component-account';
@import 'components/component-anchor';
@import 'components/component-asset-image';
@import 'components/component-autocomplete';
@import 'components/component-banner';
@import 'components/component-bg';
@import 'components/component-cards';
@import 'components/component-carousel';
@import 'components/component-compare';
@import 'components/component-download-cta';
@import 'components/component-error';
@import 'components/component-form';
@import 'components/component-forms-core';
@import 'components/component-full-width-image';
@import 'components/component-gallery';
@import 'components/component-guides';
@import 'components/component-hero';
@import 'components/component-hex';
@import 'components/component-hex-grid';
@import 'components/component-inner';
@import 'components/component-inspire';
@import 'components/component-installer';
@import 'components/component-listing';
@import 'components/component-loader';
@import 'components/component-news-popup';
@import 'components/component-progress-bar';
@import 'components/component-product-details';
@import 'components/component-promo';
@import 'components/component-quick-add';
@import 'components/component-quick-nav';
@import 'components/component-radio-box';
@import 'components/component-rect-grid';
@import 'components/component-samples';
@import 'components/component-sample-error';
@import 'components/component-scroll-panel';
@import 'components/component-search';
@import 'components/component-section-title';
@import 'components/component-slide-out-filters';
@import 'components/component-steps';
@import 'components/component-steps-navigation';
@import 'components/component-sub-nav';
@import 'components/component-tag-list';
@import 'components/component-toastr';
@import 'components/component-tooltip';
@import 'components/component-video';
