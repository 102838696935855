.anchor-offset {
  position: relative;
  top: -54px; // height of mobile sub-navigation.
  display: block;
  min-height: 1px;

  &::before {
    content: ' ';
  }

  @media (min-width: 1024px) {
    top: -81px; // height of mobile sub-navigation.
  }
}

// I know, it's a "js-" class. It works.
.js-sub-section {
  min-height: 10vh;
}
