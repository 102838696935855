.error {
	background-color: $brand-black;
}

.container {
	height: 500px;
	position: relative;
	transition: all 0.3s ease-out;
	overflow: hidden;
	perspective: 500px;
	transform-style: preserve-3d;

	.content {
		@include font-weight(bold);

		width: 200px;
		display: block;
		position: absolute;
		top: 30px;
		left: -80px;
		text-align: center;
		transition: all 0.3s ease-out 0.1s;
		transform: rotateX(-90deg) translateZ(-40px) translateY(-40px);
		pointer-events: none;
		-webkit-font-smoothing: antialiased;
		color: $brand-white;
		overflow: hidden;
		white-space: nowrap;

		opacity: 0;
	}

	.honeycomb {
		display: flex;
		justify-content: center;
		text-align: center;
		transform: rotateX(45deg) rotateY(0deg);
		transform-origin: center, 65%;
		transform-style: preserve-3d;
		z-index: 5;

		.column {
			width: 62px;
			display: block;
			transform-style: preserve-3d;

			&:nth-child(odd) {
				margin-top: 36px;
			}
		}

		.hexa {
			width: 62px;
			height: 72px;
			display: block;
			position: relative;
			margin: 0;
			cursor: pointer;
			transform-style: preserve-3d;
			transition: all 0.3s ease-out;
			opacity: 0.7;

			&:hover {
				opacity: 1;
				transform: translateZ(20px);
				transition-duration: 200ms;

				.content {
					opacity: 1;
				}
			}
		}

		.shadows {
			width: 130%;
			height: 130%;
			display: block;
			position: absolute;
			top: -15%;
			left: -15%;
			background-image: radial-gradient(closest-side, rgba($brand-black, 0), $brand-black);
			opacity: 1;
			transform: translateZ(10px);
			pointer-events: none;
		}
	}
}

.hexagon {
	width: 36px;
	height: 62px;
	display: block;
	position: relative;
	background: $brand-gold;
	transform-style: preserve-3d;

	&:before,
	&:after {
		content: "";
		position: relative;
		float: left;
		border-top: 31px solid transparent;
		border-bottom: 31px solid transparent;
	}

	&:before {
		border-right: 18px solid $brand-white;
		left: -18px;
		border-right-color: $brand-gold;
	}

	&:after {
		border-left: 18px solid $brand-white;
		right: -18px;
		border-left-color: $brand-gold;
	}

	&--404 {
		background: $brand-copper;

		&:before {
			border-right: 18px solid $brand-white;
			border-right-color: $brand-copper;
		}

		&:after {
			border-left: 18px solid $brand-white;
			border-left-color: $brand-copper;
		}
	}
}
