.progress-bar {
  align-self: center;
  max-width: 500px;
  width: 100%;
  height: 9px;

  &__track {
    background-color: $brand-grey-light;
    width: inherit;
    height: inherit;
  }

  &__bar {
    background-color: $brand-black;
    position: relative;
    height: inherit;
    transition: width 0.3s ease-in-out;
  }

  .svg--logo {
    width: 36px;
    height: 38px;
    position: absolute;
    right: -20px;
    transform: translateY(-50%);
    top: 50%;
  }
}

.appolygon-path {
  stroke-width: 4px;
  stroke: $brand-black;
  fill: $brand-white;
}
