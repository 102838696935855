.product-details {
  text-align: center;

  @include mq($until: laptop-s) {
    grid-template-columns: 1fr;
  }

  h3 {
    color: $brand-black;
    margin-bottom: 50px;
    order: 2;
  }

  h4 {
    order: 1;
    margin-bottom: 10px;
  }

  &__section {
    width: 100%;
    display: flex;
    flex-direction: column;
    border-top: 1px solid $brand-grey-light;

    &.product-details__section {
      padding: 10vw;

      @include mq(laptop-s) {
        padding: 5vw 5vw 2vw 5vw;
      }
    }

    .product-details__section-title {
      span {
        @include font-weight(book);
        opacity: 0.5;
      }
    }

    &:first-child {
      .product-details__info {
        margin-top: 0;
      }
      .tooltip-align {
        width: 0%;
      }
    }

    &:nth-of-type(2),
    &:nth-of-type(5) {
      border-left: 1px solid $brand-grey-light;
      border-right: 1px solid $brand-grey-light;

      @include mq($until: laptop-s) {
        border-left: 0;
        border-right: 0;
      }
    }

    &:nth-of-type(5) {
      .promo {
        height: calc(100% + 1px);
      }
    }

    &--promo {
      &.product-details__section--promo {
        padding: 0;

        .promo {
          min-height: 100%;
        }

        .promo__item {
          justify-content: flex-start;
        }
      }

      .grid-column {
        width: 100%;
      }

      .promo {
        padding: 0;

        h3 {
          color: $brand-white;
          order: 1;
        }

        p {
          order: 2;
        }
      }
    }

    &.has-carousel {
      .promo {
        height: 100%;
      }
    }

    &--carousel {
      .tns-outer {
        height: auto;
        order: 3;
      }

      .tns-controls {
        width: auto;
        top: 50%;
        left: -10px;
        right: -10px;
        padding: 0;
        margin-top: 30px;

        @include mq(laptop-s) {
          left: -40px;
          right: -40px;
        }

        button {
          background-image: url("~img/svg/svg--arrow-black.svg");
        }
      }

      .carousel__bg-image-wrapper {
        max-width: 200px;
        margin: 30px auto 0;

        & + p {
          margin-top: 1em;
        }
      }

      .carousel__bg-image {
        width: 100%;
        height: 0;
        padding-bottom: 100%;
        background-position: center;
        background-size: cover;
      }

      h5 {
      }

      p {
        order: 4;
        margin: 0;
      }
    }
  }

  ul {
    width: 100%;
    text-align: left;
    padding-left: 5%;
    padding-right: 5%;
    list-style: none;

    order: 3;

    li {
      width: 100%;
      margin-top: 15px;
      margin-bottom: 15px;
      display: flex;
      position: relative;

      &.product-details__spec {
        @include mq($until: mobile-l) {
          display: block;
        }
      }
    }
  }

  .product-details__icon {
    width: 15%;
    margin-top: 20px;
    margin-right: 15px;

    & + .product-details__text {
      width: 85%;
      margin-top: 20px;
    }
  }

  .product-details__title,
  .product-details__text {
    @include font-weight(bold);

    width: 100%;
    font-size: rem(14px);

    @include mq(mobile-l) {
      width: 50%;
    }
  }

  .product-details__title {
    color: $brand-black;
    margin-right: 20px;

    @include mq($until: mobile-l) {
      margin-bottom: 10px;
    }
  }

  .product-details__text {
    color: rgba($brand-black, 0.3);
    padding-right: 20px;

    span {
      position: relative;
      display: block;

      &:not(:first-child) {
        margin-top: 15px;
      }
    }

    .product-details__info {
      position: absolute;
      top: 0;
      right: 0;
    }

    .tooltip-align {
      width: 0%;
    }
  }

  .tooltip-align {
    width: 0%;
  }
  .product-details__info {
    width: 14px;
    position: relative;
    margin-top: 20px;

    @include mq($until: mobile-l) {
      position: absolute;
      top: 0;
      right: 0;
    }

    @include mq(mobile-l) {
      order: 3;
    }

    .svg {
      width: 14px;
      height: 14px;
      fill: $brand-grey-light;
    }
  }
}

.product-accessory {
  .product-details__section {
    ul.guides {
      li.guide {
        text-align: center;
      }
    }

    &.grid-column {
      @include mq($until: laptop-s) {
        grid-column: 1;
      }
    }
  }
}
