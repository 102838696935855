.sample-error {
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  background-color: $brand-gold;
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 100%);
  opacity: 0;
  padding: 10px 20px;
  transition: transform 0.3s ease-in, opacity 0.3s ease-in;

  p {
    color: $brand-white;
    margin-bottom: 0;
  }

  &.has-max-samples {
    opacity: 1;
    transform: translate(-50%, 0);
  }
}
