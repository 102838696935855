/**
 * Add typography variables in this file.
 */

$brand-font: "Berlingske Serif", serif; // @note: font usually used for title elements
$base-font-family: "HKNova", sans-serif; // @note: font usuaaly used for paragraph

// Font weight map
$font-weight: (
  book: 400,
  medium: 500,
  bold: 500
);
