.bg-pattern__holder {
  padding-top: 100px;
  position: relative;

  .bg-pattern {
    transform: translateY(-100px);
    top: 100px;
    position: sticky;
    z-index: -1;
  }
}

.bg-pattern {
  &:before {
    width: 100%;
    height: 350px;
    background-image: url('~img/svg/svg--diamond-pattern.svg');
    background-position: left bottom;
    background-size: 90px auto;
    opacity: 0.4;
    content: '';
    display: block;
    position: absolute;
    z-index: -1;
    top: 0;
  }

  &.js-is-stuck {
    &:before {
      // if not edge and IE
      @supports not (-ms-ime-align: auto) {
        position: fixed;
        top: 0;
      }
    }
  }

  &--small {
    padding-top: 64px;

    &:before {
      height: 64px;
      background-size: 54px auto;
      opacity: 1;

    }
  }

  &--full {
    height: 100%;
    min-height: calc(100vh - 93px);
    padding-top: 44px;
    padding-bottom: 44px;

    &:before {
      height: 100%;
    }
  }
}

.bg-white {
  background-color: $brand-white;
}

.bg-grey {
  &--light {
    background-color: $brand-grey-lighter;
  }

  &--havwoods {
    background-color: $brand-grey-havwoods;
    position: relative;

    &:before {
      width: 100%;
      height: 100%;
      content: '';
      display: block;
      position: absolute;
      background-image: url('~img/svg/svg--logo.svg');
      background-size: 100% 90%;
      background-position: center center;
      background-repeat: no-repeat;
      opacity: 0.04;
    }
  }
}

.bg-gold {
  background-color: $brand-gold;
}

.bg-copper {
  background-color: $brand-copper;
}
