.card {
  text-align: center;
  font-size: rem(14px);

  &--related {
    .hex.hex {
      width: 130px;
      padding-bottom: 22%;
    }

    .rect.rect {
      width: 130px;
      padding-bottom: 22%;
    }
  }

  &--remove {
    &.card--remove {
      margin: 0 auto;

      @include mq(mobile-l) {
        width: 100%;

        .hex {
          width: 85%;
        }

        .rect {
          width: 85%;
        }
      }

      h3 {
        @include font-weight(book);
        font-size: 14px;
        top: 46.33%;
        color: rgba($brand-black, 0.5);

        @include mq(mobile-l) {
          top: 43.33%;
        }
      }
    }

    .hex-close-container,
    .rect-close-container {
      position: relative;
    }

    .close-container {
      width: 40px;
      height: 40px;
      background-color: $brand-grey-light;
      border-radius: 40px;
      bottom: 4%;
      left: 50%;
      transform: translate3d(-50%, 0, 0);
      position: absolute;
      z-index: 2;

      @include mq(mobile-l) {
        bottom: 15%;
      }

      &:hover {
        background-color: lighten($brand-grey-light, 4%);
      }

      button {
        cursor: pointer;
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        width: 100%;
      }
    }

    .close-icon {
      position: absolute;
      top: 18px;
      left: 14px;

      &:after,
      &:before {
        width: 14px;
        height: 2px;
      }

      &:after {
        transform: rotate(-45deg) translateY(-1.5px);
      }

      &:before {
        transform: rotate(45deg) translateY(1px);
      }
    }
  }

  &--inspire {
    width: 33%;

    h2 {
      font-size: 20px;
    }

    .promo__item--inner {
      height: 100%;
      justify-content: center;
      opacity: 0;
      transition: opacity 0.4s ease-in-out;
      background-color: rgba($brand-black, 0.3);

      .btn {
        margin-top: 10px;
      }
    }

    .promo__zoom-bg {
      &:before {
        display: none;
      }
    }

    &:hover {
      .promo__item--inner {
        opacity: 1;
      }
    }
  }

  &--accessory {
    .accessory-image {
      margin-bottom: 15px;
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  &--product,
  &--promo {
    width: 160px;
    height: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    padding-top: rem(25px);
    padding-bottom: rem(25px);

    &__single {
      @include mq($until: tablet) {
        width: 100%;
      }
    }

    @include mq(mobile-l) {
      width: 220px;

      .grid-container--two-third-right & {
        width: 285px;
      }
    }
  }

  &--promo {
    width: 100%;
    border: 1px solid $brand-grey-havwoods;
    position: relative;
    transition: visibility 0.4s ease-in-out;
    visibility: hidden;

    .btn {
      width: 80%;
    }

    &.is-active {
      visibility: visible;
      display: block;
    }

    &.is-hidden {
      display: none;
    }

    .close-icon {
      position: absolute;
      top: 18px;
      right: 15px;
    }
  }

  &--post {
    width: 100%;
    padding-bottom: 20px;
    transition: background-color 0.4s ease-in-out;

    &:hover {
      background-color: rgba($brand-grey-light, 0.25);
    }

    .card-image {
      height: 234px;
      background-position: center center;
      background-size: cover;
      position: relative;

      span {
        @include font-weight(bold);

        background-color: $brand-white;
        color: $brand-grey-havwoods;
        position: absolute;
        top: 10px;
        left: 10px;
        padding: rem(13px 25px);
      }
    }

    h3,
    h4 {
      color: $brand-heading;
      padding-right: 20px;
      padding-left: 20px;
    }

    h3 {
      margin-bottom: rem(30px);
    }

    p {
      color: $brand-grey-havwoods;
      text-transform: none;
      padding-right: 20px;
      padding-left: 20px;

      &.card__type {
        margin-bottom: rem(25px);
        font-size: 10px;

        @include mq(tablet) {
          font-size: 14px;
        }
      }

      &.card__description {
        font-size: 14px;
        line-height: 25px;
      }
    }

    a {
      color: $brand-grey-havwoods;
    }
  }

  &.card--post-highlight {
    width: auto;

    &:hover {
      background-color: transparent;
    }
  }

  &--product-text-filter,
  &--product-image-filter,
  &--product-switch-filter {
    @include font-weight(bold);

    color: $brand-black;
    font-size: rem(10px);
    label {
      cursor: pointer;
    }
  }

  &--product-text-filter {
    text-align: left;
    margin-bottom: 5px;
    margin-top: 0;

    label {
      margin-left: rem(12px);
      display: inline-block;

      &:before {
        content: "";
        width: 16px;
        height: 16px;
        border: 2px solid rgba($brand-black, 0.1);
        display: inline-block;
        margin-right: 12px;
        transition: border-color 0.4s ease-in-out;
        transform: translateY(5px);
      }

      &:hover:before {
        border-color: $brand-grey-dark;
      }
    }

    @at-root #{&}.is-selected label:before {
      border-color: $brand-copper;
      background-image: url("~img/svg/svg--tick.svg");
      background-position: center center;
      background-repeat: no-repeat;
    }
  }

  &--product-image-filter {
    position: relative;

    &:hover {
      .product-filter-image {
        border-color: $brand-grey-dark;
      }

      &:before {
        transform: translateX(-50%) scaleX(1);
      }
    }

    &:before {
      width: 60%;
      height: 2px;
      content: "";
      display: block;
      background-color: $brand-grey-dark;
      position: absolute;
      bottom: -5px;
      left: 50%;
      transform: translateX(-50%) scaleX(0);
      transition: transform 0.4s ease-in-out;
    }

    &.is-selected {
      .product-filter-image {
        border-color: $brand-copper;
      }

      &:before {
        background-color: $brand-copper;
        transform: translateX(-50%) scaleX(1);
      }
    }

    .product-filter-image {
      height: 90px;
      background-size: cover;
      background-position: center center;
      margin-bottom: 10px;
      border: 2px solid $brand-white;
      transition: border-color 0.4s ease-in-out;
    }
  }

  &--product-switch-filter {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 19px;
    text-align: left;

    span {
      padding-right: 10px;
    }

    .fake-radio-container {
      width: 40px;
      height: 22px;
      display: block;
      border: 2px solid rgba($brand-black, 0.1);
      border-radius: 40px;
      position: relative;
      transition: all 0.4s ease-in-out;

      .fake-radio {
        width: 20px;
        height: 20px;
        border-radius: 20px;
        display: block;
        background-color: $brand-white;
        box-shadow: 0 0 5px rgba($brand-black, 0.4);
        position: absolute;
        top: 50%;
        left: -1px;
        transform: translateY(-50%);
        cursor: pointer;
        transition: all 0.4s ease-in-out;
      }
    }

    &.is-selected {
      .fake-radio-container {
        background-color: $brand-copper;
        border-color: $brand-copper;
      }

      .fake-radio {
        left: 17px;
      }
    }
  }

  &--product-shown {
    width: 120px;

    .hex-bordered,
    .hex {
      width: 120px !important;
      margin-left: 0;

      .svg {
        width: 128px;
        height: 147px;
        left: -4px;
      }
    }

    .rect-bordered,
    .rect {
      width: 120px !important;
      margin-left: 0;

      .svg {
        width: 128px;
        height: 147px;
        left: -4px;
      }
    }

    .product-code,
    .product-name,
    .product-range {
      @include font-weight(bold);

      font-size: 14px !important;
      line-height: 1.7em;
      color: $brand-white !important;
    }
  }

  .card-details {
    &__time {
      width: 40px;
      height: 46px;
      display: flex;
      justify-content: center;
      align-content: center;
      align-items: center;
      position: relative;
      margin: -28px auto 8px;

      &-inner {
        z-index: 2;
        color: $brand-grey-havwoods;
        font-size: 12px;
        transform: translate(1px, -1px);

        span {
          @include font-weight(bold);
          display: block;
          margin-bottom: -2px;
          font-size: 14px;
        }
      }
    }

    &__type {
      margin-bottom: rem(26px);
    }
  }

  .card-link {
    text-decoration: none;
    flex: 1 0 auto;

    &__icon {
      position: relative;
      display: flex;
      align-items: center;
      height: 46px;
      justify-content: center;
      margin: 6px auto;
      text-decoration: none;
      width: 40px;

      .hex-icon {
        z-index: 3;
      }

      .rect-icon {
        z-index: 3;
      }

      .svg--inner {
        color: $brand-white;
        height: 19px;
        width: 20px;
        z-index: 4;
      }
    }

    &:hover {
      .btn {
        background-color: rgba($brand-gold, 0.1);

        &:before {
          transform: scaleX(1);
        }
      }
    }
  }

  .card-link--highlight {
    position: relative;
    padding: 40px 0 0;
    width: 100%;
    display: block;

    .card-link__media {
      width: 100%;
      height: 215px;

      @include mq(tablet) {
        position: absolute;
        left: 0;
        top: 0;
        width: 70%;
        height: 90%;
      }
    }

    .card-link__image {
      object-fit: cover;
      object-position: center center;
      width: 100%;
      height: 100%;
    }

    .card-link__content {
      background-color: $brand-white;
      text-align: left;
      padding: 20px 0;
      box-shadow: 0px 0px 9px 1px rgba(0, 0, 0, 0.4);

      @include mq(tablet) {
        position: relative;
        padding: 30px 40px;
        margin-right: 10px;
        width: 70%;
        margin: auto 10px auto auto;
      }

      p {
        font-size: 16px;
        line-height: 26px;
      }
    }
  }

  .hex,
  .rect,
  .product-tag-container {
    margin: 0 auto;
    position: relative;
    width: 100%;

    @include mq(mobile-l) {
      width: 220px;

      .grid-container--two-third-right & {
        width: 95%;
      }
    }
  }

  .product-tag-wrapper {
    display: flex;
    gap: 0.5rem;
    flex-direction: column;
    position: absolute;
    align-items: flex-start;
  }

  .hex {
    padding-bottom: 40%;
  }

  .product-tag {
    font-weight: 500;
    background-color: $brand-gold;
    color: #ffffff;
    padding: 0.8125rem 1.5625rem;
    z-index: 2;

    @include mq($until: mobile-l) {
      font-weight: 500;
      background-color: $brand-gold;
      color: #ffffff;
      padding: 0.425rem 0.925rem;
      z-index: 2;
      height: 28px;
    }
  }

  @media screen and (max-width: 425px) {
    .product-desc {
      height: 100px;
    }
  }

  .product-code {
    font-size: 12px;
    color: $brand-grey-havwoods;
    margin-bottom: rem(8px);
  }

  .product-price {
    @include font-weight(bold);
    color: $brand-red;
    font-size: 14px !important;
    &--crossed {
      @include font-weight(book);
      text-decoration: line-through;
      color: $brand-black;
      margin-bottom: 5px;
    }
  }

  .product-name {
    @include font-weight(medium);

    font-family: $brand-font;
    color: $brand-black;
    margin-bottom: rem(8px);
  }

  .product-range {
    @include font-weight(bold);

    color: $brand-black;
    font-size: rem(12px);
    margin-bottom: rem(23px);
  }

  .product-promo {
    @include font-weight(bold);

    color: $brand-black;
    font-size: rem(20px);
    margin-bottom: rem(22px);
    padding-left: 10px;
    padding-right: 10px;
  }

  &--product-small {
    margin-top: 10px;
    margin-bottom: 10px;

    .card-link {
      display: flex;

      .product-details {
        margin-left: 20px;
        margin-top: 4px;
        text-align: left;
      }
    }

    .hex,
    .rect {
      width: 41px;
      padding-bottom: 0;
      margin: 0;
    }
  }
}

.listing {
  .grid-column {
    &.card--product {
      width: 100%;
    }
  }
}
