.carousel {
  width: 100%;
  height: 100%;
  position: relative;

  &--centered {
    .card {
      margin: 0 auto;
    }
  }

  &--arrows {
    .carousel__item {
      &:first-child {
        &.tns-slide-active {
          margin-left: 0 !important;
        }
      }
    }
  }

  &__item {
    .steps-answer & {
      @include mq(desktop-s) {
        padding-right: 40px !important;
      }

      &:first-child.tns-slide-active {
        @include mq(desktop-s) {
          margin-left: 20px !important;
        }
      }
    }

    &:hover {
      a {
        opacity: 1;
      }
    }

    &--image {
      width: 100%;
      height: 100%;
      padding: 0;
      background-size: cover;
      background-position: center center;
      position: relative;

      &:first-child {
        &.tns-slide-active {
          margin-left: 0 !important;
        }
      }
    }

    &--gallery {
      height: 75vh;
      background-size: cover;
      background-position: center center;
      position: relative;

      &:first-child {
        &.tns-slide-active {
          margin-left: 0 !important;
        }
      }
    }

    &:first-child {
      &.tns-slide-active {
        @include mq(tablet) {
          margin-left: 7px;

          .steps-answer & {
            margin-left: 0;
          }
        }

        @include mq(laptop-l) {
          margin-left: 15px;
        }

        @include mq(desktop-m) {
          margin-left: 29px;
        }
      }
    }
  }
}

.carousel-nav {
  display: flex;
  margin-top: rem(60px);
  margin-bottom: rem(140px);

  .steps-answer & {
    margin-bottom: rem(60px);
  }

  .line-nav {
    width: 100%;
    display: block;
    height: 5px;
    background-color: $brand-grey-lighter;
    border: 0;
    cursor: pointer;
    outline: 0;

    &.tns-nav-active {
      background-color: $brand-gold;
    }
  }

  &--gallery {
    padding-bottom: 25px;
    margin-top: 0;
    margin-bottom: 0;
  }

  &--full-gallery {
    width: 50%;
    height: 4px;
    position: absolute;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    bottom: 27px;
    left: 50%;
    transform: translateX(-50%);
    margin-bottom: rem(0);

    .line-nav {
      width: 42px;
      height: 4px;
      margin-left: 4px;
      margin-right: 2px;
      background-color: rgba($brand-white, 0.4);

      &.tns-nav-active {
        background-color: $brand-white;
      }
    }
  }
}

.hex-bordered {
  width: 80px;
  outline: 0;
  margin-right: 20px;
  position: relative;
  z-index: 2;

  .hex-edge-fix {
    width: 100%;
    height: 92px;
    z-index: 3;
    display: block;
    position: absolute;
    padding-bottom: 20px;
    left: 0;
    top: 0;
  }

  &:last-child {
    margin-right: 0;
  }

  &.tns-nav-active {
    .svg {
      stroke: $brand-gold;
    }
  }

  .svg {
    width: 86px;
    height: 100px;
    position: absolute;
    display: block;
    z-index: 1;
    top: -4px;
    left: -3px;
    transition: stroke 0.4s ease-in-out;
  }
}

.tns-outer,
.tns-ovh,
.tns-inner {
  width: 100%;
  height: 100%;
  position: relative;
}

.tns-controls {
  width: 100%;
  display: flex;
  justify-content: space-between;
  transform: translateY(-50%);
  position: absolute;
  z-index: 2;
  outline: 0;
  top: 100%;
  padding-left: 30px;
  padding-right: 30px;

  @include mq(mobile-l) {
    top: 50%;
  }

  button {
    width: 52px;
    height: 52px;
    border: 0;
    background: url('~img/svg/svg--arrow.svg') transparent no-repeat center;
    outline: 0;
    padding: 0;

    &:disabled {
      opacity: 0.4;
      cursor: default;
    }

    &:first-child {
      transform: rotate(90deg);
    }

    &:last-child {
      transform: rotate(-90deg);
    }
  }
}
