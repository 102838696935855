.slide-out-filters {
  height: 100vh;
  position: absolute;
  z-index: 3;
  left: -469px;
  transition: left 0.4s ease-in-out;

  @include mq($until: laptop-s) {
    width: 100%;
    left: -100%;
    top: 0;
    position: fixed;
  }

  &__tab,
  &__btn {
    height: 38px;
    background-color: $brand-black;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    color: $brand-white;

    font-size: rem(12px);
    text-decoration: none;

    &-inner {
      width: 117px;
      position: absolute;
    }
  }

  &__btn {
    margin-top: 5px;

    @include mq(mobile) {
      width: 144px;
      margin-top: 0;
      margin-left: 5px;
      order: 2;
    }

    @include mq(laptop-s) {
      order: 1;
      margin-right: 5px;
    }

    .filter-accessory & {
      display: none;
    }
  }

  &__tab {
    position: relative;

    .filter-icon {
      position: absolute;
      margin-left: 7px;
      transform: rotate(-90deg) translateY(-50%);
      right: 0;

      @include mq(laptop-s) {
        right: auto;

        span {
          &:nth-of-type(1) {
            width: 16px;
          }

          &:nth-of-type(3) {
            width: 6px;
          }
        }
      }

      span {
        height: 2px;
        display: block;
        background-color: $brand-white;
        margin: 2px auto;
        transition: width 0.4s ease-in-out 0.4s;

        &:nth-of-type(1) {
          width: 16px;
        }

        &:nth-of-type(2) {
          width: 10px;
        }

        &:nth-of-type(3) {
          width: 6px;
        }
      }
    }

    @include mq(laptop-s) {
      width: 50px;
      height: 290px;
      position: absolute;
      top: 20%;
      right: -50px;

      .filter-icon {
        bottom: 21px;

        span {
          &:nth-of-type(1) {
            width: 16px;
          }

          &:nth-of-type(2) {
            width: 10px;
          }

          &:nth-of-type(3) {
            width: 6px;
          }
        }
      }

      &-inner {
        transform: rotate(-90deg);
      }
    }
  }

  &__content {
    width: 100%;
    height: 100vh;
    overflow: auto;
    background-color: $brand-white;
    box-shadow: 0;
    padding-top: 29px;
    padding-bottom: 29px;
    padding-left: 29px;
    padding-right: 29px;

    @include mq(laptop-s) {
      width: 469px;
      padding-left: 47px;
      padding-right: 47px;
    }
  }

  &.is-sticky {
    position: fixed;
    top: 0;
  }

  &.is-open {
    top: 0;
    left: 0;
    z-index: 2001;

    .filter-icon {
      span {
        &:nth-of-type(1) {
          width: 6px;
        }

        &:nth-of-type(3) {
          width: 16px;
        }
      }
    }

    @include mq(laptop-s) {
      position: fixed;
      box-shadow: 6px 0 3px -2px rgba($brand-black, 0.16);
    }
  }
}

.product-filters {
  grid-column-gap: 20px;
  grid-row-gap: 20px;
}
