.steps-navigation {
  &:not(.steps-navigation--mobile) {
    top: 0;
    position: sticky;
    z-index: 3;
  }

  &__inner {
    background-color: $brand-grey-lighter;
    display: flex;
    justify-content: space-between;
  }

  &__center {
    display: inline-flex;
    max-width: 500px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  &:not(.steps-navigation--mobile) {
    .steps-navigation__inner {
      min-height: 78px;

      @include mq($until: laptop-l) {
        padding-left: 24px;
        padding-right: 24px;
      }
    }

    .steps-navigation__right,
    .steps-navigation__left {
      @include mq($until: laptop-l) {
        display: none;
      }
    }
  }

  &__left,
  &__right {
    flex: 0 0 400px;
  }

  &__left {
    .link-pagination__inner {
      flex-direction: row-reverse;
    }
  }

  &__has-restart {
    display: flex;
    align-items: center;
  }

  &--mobile {
    display: none;

    @include mq($until: laptop-l) {
      position: fixed;
      bottom: 0;
      z-index: 4;
      display: block;
      left: 0;
      right: 0;
    }

    .steps-navigation__right,
    .steps-navigation__left {
      flex: 0 0 35%;
    }

    .steps-navigation__center {
      flex: 0 0 30%;
    }
  }
}

.link-pagination,
.link-restart {
  text-decoration: none;
}

.link-pagination {
  background-color: $brand-black;
  color: $brand-white;
  padding: 14px;
  display: block;
  width: 100%;
  cursor: pointer;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.3;
  }

  @include mq(laptop-l) {
    padding: 14px 52px;
    max-width: 228px;
  }

  .svg--arrow {
    fill: $brand-white;
    transform: rotate(90deg);
    height: 34px;
    width: 9px;

    @include mq(laptop-l) {
      height: 51px;
    }
  }

  &--next {
    .svg--arrow {
      transform: rotate(-90deg);
    }
  }

  &__inner {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: row;
  }

  &__text {
    font-size: 12px;
  }
}

.link-restart {
  width: 100%;
  text-align: center;
  height: 100%;
  cursor: pointer;

  @include mq($until: laptop-l) {
    align-items: center;
    display: flex;
    justify-content: center;
  }

  @include mq(laptop-l) {
    max-width: 172px;
  }

  .link-pagination__text {
    color: $brand-black;
  }
}
