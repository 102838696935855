// https://codepen.io/weaintplastic/pen/qEMZbx

@for $i from 1 through 6 {
 

  @keyframes preload-cycle-#{$i} {
    $startIndex: $i * 5;
    $reverseIndex: (80 - $i * 5);

    #{ $startIndex * 1% } {
      transform: rotateZ(60 * $i + deg) rotateY(90deg) rotateX(0deg);
      border-left-color: $brand-copper;
    }

    #{ $startIndex + 5% },
    #{ $reverseIndex * 1% } {
      transform: rotateZ(60 * $i + deg) rotateY(0) rotateX(0deg);
      border-left-color: $brand-gold;
    }

    #{$reverseIndex + 5%},
    100% {
      transform: rotateZ(60 * $i + deg) rotateY(90deg) rotateX(0deg);
      border-left-color: $brand-copper;
    }
  }
}

@keyframes preload-flip {
  0% {
    transform: rotateY(0deg) rotateZ(-60deg);
  }

  100% {
    transform: rotateY(360deg) rotateZ(-60deg);
  }
}

.loading-indicator {
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: center;
  justify-items: center;

  &--inline {
    justify-content: center;
    width: 100%;
    min-height: 35em;
  }

  &--overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
  }
}

.preloader {
  width: 60px;
  height: 68px;

  &__section {
    border-top: 18px solid transparent;
    border-right: none;
    border-bottom: 16px solid transparent;
    border-left: 30px solid $brand-gold;
    transform-origin: left bottom;
    position: absolute;
    top: 0;
    left: 30px;
  }

  &.loading {
    animation: 5s preload-flip steps(2) infinite both;

    @for $i from 1 through 6 {
      .preloader__section:nth-child(#{$i}) {
        transform: rotateZ(60 * $i + deg) rotateY(90deg) rotateX(0);
        animation: 5s preload-cycle-#{$i} linear infinite both;
      }
    }
  }
}
