.radio {
  .radio-label__content {
    margin-left: 20px;
    margin-right: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 41px;
    min-height: 42px;
    position: relative;
    padding-top: 8px;
    padding-bottom: 8px;

    @include mq(tablet) {
      height: 83px;
      min-height: 83px;
      margin-left: 40px;
      margin-right: 40px;
    }
  }

  .radio-label__text {
    display: block;

    font-weight: 500;
    color: $brand-black;
  }

  input[type="checkbox"] {
    position: absolute;
    opacity: 0;

    + .radio-label {
      width: 100%;
      display: block;
      text-align: left;
      position: relative;
      cursor: pointer;

      img {
        display: none;

        @include mq(laptop-l) {
          display: block;
        }
      }

      .radio-label__content {
        &:after {
          content: "";
          flex-shrink: 0;
          background: $brand-white;
          border-radius: 100%;
          border: 1px solid darken($brand-black, 25%);
          display: inline-block;
          width: 23px;
          height: 23px;
          position: relative;
          vertical-align: top;
          cursor: pointer;
          text-align: center;
          transition: all 0.25s ease;
          margin-left: 15px;

          @include mq(tablet) {
            width: 31px;
            height: 31px;
          }
        }
      }
    }
    &:checked {
      + .radio-label {
        .radio-label__content {
          &:after {
            background-color: $brand-black;
            box-shadow: inset 0 0 0 4px $brand-white;

            @include mq(tablet) {
              box-shadow: inset 0 0 0 6px $brand-white;
            }
          }
        }
      }
    }
    &:focus {
      + .radio-label {
        .radio-label__content {
          &:after {
            outline: none;
            border-color: $brand-black;
          }
        }
      }
    }
    &:disabled {
      + .radio-label {
        .radio-label__content {
          &:after {
            box-shadow: inset 0 0 0 4px $brand-white;
            border-color: darken($brand-black, 25%);
            background: darken($brand-black, 25%);

            @include mq(tablet) {
              box-shadow: inset 0 0 0 6px $brand-white;
            }
          }
        }
      }
    }
    + .radio-label {
      &:empty {
        &:after {
          margin-right: 0;
        }
      }
    }
  }
}
