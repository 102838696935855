.full-width-image {
  height: 500px;
  position: relative;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
  }

  .section-title {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &--text-dark {
    .section-title {
      text-shadow: rgba(255, 255, 255, 0.2) 1px 1px 1px;

      h1,
      h2,
      h3 {
        color: hsl(0, 0, 0%);
      }

      h4 {
        color: hsl(0, 0, 28%);
      }
    }
  }

  &--text-light {
    .section-title {
      text-shadow: rgba(0, 0, 0, 0.2) 1px 1px 1px;

      h1,
      h2,
      h3 {
        color: hsl(0, 0, 100%);
      }

      h4 {
        color: hsl(0, 0, 62%);
      }
    }
  }
}
