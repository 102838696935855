.download-cta {
    border: 1px solid $brand-black;
    font-size: rem(12px);
    padding: 10px;
    order: 4;
    text-decoration: none;
    display: block;
    margin-top: auto;

    &:hover {
        .download-cta__title {
            &:before {
                width: 50%;
            }
        }
    }

    &--inline {
        border: 0;
        margin-top: 0;
    }

    &__title {
        color: $brand-copper;
        margin-bottom: 10px;

        position: relative;
        display: inline-block;

        &:before {
            width: 0%;
            height: 1px;
            content: "";
            position: absolute;
            background-color: $brand-copper;
            bottom: -5px;
            left: 50%;
            transform: translateX(-50%);
            transition: all 0.4s ease-in-out;
        }
    }

    p {
        line-height: rem(20px);
        margin-bottom: 0;
        padding-left: 14%;
        padding-right: 14%;
        color: $brand-text;
    }

    .svg {
        width: 10px;
        height: 14px;
        fill: $brand-copper;
        transform: translate(-5px, 2px);
    }
}
