/**
 * Add brand variables in this file.
 */

$brand-white: #ffffff;
$brand-black: #000000;

$brand-red: #dd0a0a;
$brand-pink: #ff4770;
$brand-gold: #bba25c;
$brand-copper: #b56025;
$brand-green: #01cc9a;

$brand-grey-havwoods: #484848;
$brand-grey-darkest: #3F3F3F;
$brand-grey-dark: #707070;
$brand-grey-light: #e1e1e1;
$brand-grey-lighter: #f6f6f6;


$brand-heading: $brand-black;
$brand-text: $brand-grey-dark;
