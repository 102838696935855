.ktc-form-wrapper .ktc-form:not(&.ktc-form-modal-content) {
  .field-container {
    margin-bottom: 10px;
    position: relative;

    select {
      @include font-weight(bold);

      text-indent: 0;
      padding-top: 10px;
      padding-bottom: 10px;
      padding-left: 18px;
      border: 1px solid $brand-grey-light;
      color: $brand-black;
      font-size: rem(14px);
      appearance: none;
      outline: 0;
      font-family: $base-font-family;
      padding-right: 48px;
      background-image: url("~img/svg/svg--chevron.svg");
      background-size: 11px 6px;
      background-repeat: no-repeat;
      background-position: calc(100% - 18px) center;

      &:focus {
        outline: none;
        border: 1px solid $brand-black;
      }
    }
  }

  &.is-not-valid {
    & > select {
      border-color: $brand-red;
      color: $brand-red;

      &::placeholder {
        color: $brand-red;
      }
    }
  }
}

.form__label,
.fake-label {
  display: inline-block;
  margin-bottom: 10px;
}

.field-container__input,
.field-container__textarea {
  @include font-weight(bold);

  width: 100%;
  display: block;
  margin-bottom: 14px;
  color: $brand-black;
  font-size: rem(14px);
  padding-top: 14px;
  padding-bottom: 14px;
  padding-left: 10px;
  padding-right: 10px;

  border: 1px solid $brand-grey-light;

  &::-webkit-input-placeholder {
    color: $brand-black;
    opacity: 1;
    font-family: $base-font-family;
  }

  &::-moz-placeholder {
    color: $brand-black;
    opacity: 1;
    font-family: $base-font-family;
  }

  &:-ms-input-placeholder {
    color: $brand-black;
    opacity: 1;
    font-family: $base-font-family;
  }

  &:-moz-placeholder {
    color: $brand-black;
    opacity: 1;
    font-family: $base-font-family;
  }

  &:focus {
    outline: none;
    border-color: $brand-black;
  }

  .is-not-valid & {
    border-color: $brand-red;

    &::placeholder {
      color: $brand-red;
    }
  }
}

.field-container__select {
  .is-not-valid & {
    border-color: $brand-red;
  }
}

.field-container__group {
  margin-bottom: 5px;
}

.field-container input[type="checkbox"] {
  height: 1px;
  width: 1px;
  visibility: hidden;
  text-indent: -100000px;
}

input[type="checkbox"] ~ .form__label {
  @include font-weight(bold);

  margin-top: 20px;
  margin-left: 2px;
  color: rgba($brand-black, 0.5);
  font-size: rem(10px);

  text-align: left;
  position: relative;
  display: inline-block;
  width: auto;
  min-height: 30px;
  cursor: pointer;

  .sample-forms & {
    margin-bottom: 0;
  }
}

input[type="checkbox"] ~ .form__label {
  &:before {
    content: "";
    width: 16px;
    height: 16px;
    border: 2px solid rgba($brand-black, 0.3);
    display: inline-block;
    margin-right: 12px;
    transition: border-color 0.4s ease-in-out;
    transform: translateY(5px);

    .is-not-valid & {
      border: 1px solid $brand-red;
    }
  }
}

.field-container input[type="checkbox"][value="true"] {
  margin: 30px;
}

input[type="checkbox"][value="true"] + .form__label {
  &:before {
    content: "";
    border-color: $brand-copper;
    background-image: url("~img/svg/svg--tick.svg");
    background-position: center center;
    background-repeat: no-repeat;
  }
}

.is-not-valid-msg {
  color: $brand-red;
  display: none;

  .is-not-valid & {
    display: block;
  }
}

.field-validation-error {
  color: $brand-red;
  font-size: rem(10px);

  transform: translateY(3px);
}

.input-validation-error {
  color: $brand-red;
  border: 1px solid rgba($brand-red, 0.25);
}

.input-validation-error {
  color: $brand-red;
  border: 1px solid rgba($brand-red, 0.25);
}
