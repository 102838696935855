.tippy-tooltip,
.tippy-tooltip.info-theme {
    width: 200px;

    @include mq(mobile-l) {
        width: 314px;
    }

    background-color: #322b2a;
    border-radius: 0;
    margin-top: -2px;

    .tippy-arrow {
        display: none;
    }

    .tippy-content {
        @include font-weight(bold);

        padding: 10px;
        font-family: $base-font-family;
        font-size: rem(12px);

        color: $brand-white;

        &:after {
            width: 0;
            height: 0;
            content: "";
            position: absolute;
            top: 50%;
            right: -8px;
            transform: translateY(-50%);
            border-style: solid;
            border-width: 10px 0 10px 10px;
            border-color: transparent transparent transparent $brand-black;
        }
    }
}

.tippy-tooltip.info-theme {
    background-color: $brand-grey-light;

    .tippy-content {
        text-transform: none;
        color: $brand-text;

        &:after {
            border-color: transparent transparent transparent $brand-grey-light;
        }
    }
}
