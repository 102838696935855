.inner-page {
  .site-wide {
    p {
      &.large {
        text-align: center;
        margin-bottom: 30px;
      }
    }

    .download-cta {
      display: inline-block;
    }
  }

  &__content {
    display: block;

    @include mq(tablet) {
      display: flex;
    }
  }

  &__text {
    width: 100%;
    order: 1;

    p,
    ul {
      font-size: rem(14px);
      line-height: rem(25px);
    }

    li {
      margin-top: 10px;
      margin-bottom: 10px;
    }

    a {
      color: $brand-black;
    }
  }

  &__sidebar {
    width: 100%;
    max-width: 100%;
    padding: 40px;
    background-color: $brand-grey-havwoods;
    color: $brand-white;
    text-align: center;
    align-self: flex-start;
    margin-bottom: 40px;
    order: 2;

    @include mq(tablet) {
      max-width: 361px;
    }

    & + .inner-page__text {
      margin-right: 5%;
    }

    &-title {
      @include font-weight(bold);

      margin-bottom: 24px;
    }

    &-text {
      p {
        font-size: 18px;
      }
    }

    &-testimonial {
      @include font-weight(bold);

      font-size: 108px;
      line-height: 0;
      margin-top: 60px;
      opacity: 0.18;
    }
  }

  &__details {
    @include font-weight(bold);

    display: block;
    text-align: center;
    justify-content: center;
    margin-bottom: 30px;

    @include mq(mobile-l) {
      display: flex;
    }

    .inner-page__details-item {
      color: $brand-black;
      font-size: 14px;
      margin: 15px;
      background-repeat: no-repeat;

      a {
        text-decoration: none;
        color: $brand-black;
      }

      &--icon {
        width: 31px;
        height: 21px;
        display: inline-block;
        background-repeat: no-repeat;
        background-position: center center;
        margin-right: 22px;
        transform: translateY(5px);
      }

      &--phone {
        background-image: url("~img/svg/svg--phone.svg");
      }

      &--distance {
        background-image: url("~img/svg/svg--pin.svg");
      }
    }
  }

  &__center-area {
    display: flex;
    justify-content: center;
  }
}
