.asset-image {
  display: flex;
  justify-content: center;
  position: relative;

  img {
    object-fit: cover;
    align-self: center;
    @media screen and (max-width: 768px) {
      max-height: 327px;
    }
  }

  &:hover {
    a.lbox-btn[data-gallery='assetImage'] {
      opacity: 1;
    }
  }
}

.img {
  &--centered-no-margin {
    left: 50%;
    transform: translateX(-50%);
    position: relative;
  }

  &--centered {
    margin-top: 50px;
    margin-bottom: 50px;
    left: 50%;
    transform: translateX(-50%);
    position: relative;
  }

  &--right-no-margin {
    float: right;
  }

  &--right {
    margin-top: 50px;
    margin-bottom: 50px;
    float: right;
  }

  &--left-no-margin {
    float: left;
  }

  &--left {
    margin-top: 50px;
    margin-bottom: 50px;
    float: left;
  }
}
