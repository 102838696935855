$ease-fn: cubic-bezier(0.215, 0.61, 0.355, 1);

.toastify {
  &.toastify {
    position: fixed;
    right: auto;
    left: 50%;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    padding: 10px 20px;
    color: $brand-white;
    font-size: rem(14px);
    transform: translate(-50%, 0);
    // plugin uses position :(
    transition: bottom $ease-fn 0.3s, opacity $ease-fn 0.3s, top $ease-fn 0.3s;
  }

  &.comparison-warning {
    background: $brand-gold;
  }

  &.sample-warning {
    background: $brand-green;
  }
}
