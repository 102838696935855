.steps-question {
  padding-top: 25px;
  padding-bottom: 25px;
  text-align: center;

  @include mq(tablet) {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  h2 {
    font-size: 14px;
    color: $brand-black;

    @include mq(tablet) {
      font-size: 20px;
    }
  }
}

.card-answer {
  border: 1px solid $brand-grey-light;

  @include mq($until: laptop-l) {
    margin-bottom: 20px;
  }

  img {
    width: 100%;
  }
}

.steps-answer {
  margin-left: 20px;
  margin-right: 20px;

  @include mq(tablet) {
    margin-left: 32px;
    margin-right: 32px;
  }

  @include mq(laptop-l) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 32px;
    grid-row-gap: 32px;
  }
}
