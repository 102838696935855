.samples {
  max-width: 910px;
  display: block;
  justify-content: center;
  padding-bottom: 36px;

  @include mq(mobile-l) {
    display: flex;
  }

  &__item {
    @include mq(mobile-l) {
      width: 33%;
      max-width: 256px;
    }
  }
}

.samples-title,
.samples-subtitle {
  @include font-weight(bold);

  text-align: center;

  color: $brand-black;
  font-size: 20px;
  margin-bottom: 38px;
}

.samples-title {
  padding-top: 36px;
}

.samples-subtitle {
  color: rgba($brand-black, 0.2);
  font-size: 12px;
  margin-bottom: 44px;
}

.samples-button {
  text-align: center;
  margin-bottom: 44px;
}

.sample-forms {
  width: 100%;
  width: calc(100% + 17px);
  margin-left: -17px;
  padding-top: 1px;
  padding-bottom: 36px;
}

.sample-forms__culture-warning {
  background-color: $brand-gold;
  padding: 30px;

  p {
    font-size: 14px;
    line-height: 25px;
    margin: 0;
    color: $brand-white;
    text-align: center;
  }
}

.sample-project-details {
  position: relative;

  .sample-delivery-details {
    margin-top: 0;

    @include mq(laptop-l) {
      position: absolute;
      top: 0;
      right: 0;
      border-left: 1px solid rgba($brand-black, 0.1);
      padding-left: 40px;
      transform: translateX(calc(100% + 20px));
    }

    @include mq(desktop-s) {
      transform: translateX(calc(100% + 80px));
    }

    a,
    p {
      @include font-weight(bold);

      font-size: 12px;
      line-height: 34px;
      color: rgba($brand-black, 0.4);
    }

    .form-search-postcode-links {
      margin-bottom: 30px;
    }
  }
}
