.promo {
  display: flex;

  &:not(.promo--flex-only) {
    display: grid;
  }

  &--nav {
    max-width: 1520px;
    margin: 0 auto;

    .promo__item--ratio-11-16 {
      @include mq($until: laptop-s) {
        padding-top: 50%;
      }
    }

    .promo__item--container {
      @include mq($until: laptop-s) {
        padding-top: 150%;

        h3 {
          font-size: rem(20px);
        }
      }
    }

    @include mq(laptop-s) {
      transform: translateX(-9px);
    }

    &.promo--pixel-gap {
      grid-row-gap: 1px;

      @include mq(laptop-l) {
        grid-column-gap: 1px;
        grid-row-gap: 0;
      }
    }
  }

  &--pixel-gap {
    grid-row-gap: 1px;

    @include mq(tablet) {
      grid-column-gap: 1px;
      grid-row-gap: 0;
    }
  }

  &--twenty-gap {
    grid-row-gap: 20px;

    @include mq(tablet) {
      grid-column-gap: 20px;
      grid-row-gap: 0;
    }
  }

  &--gutter {
    max-width: 1390px;
    margin-left: auto;
    margin-right: auto;
    grid-row-gap: 40px;

    @include mq(tablet) {
      grid-column-gap: 40px;
      grid-row-gap: 0;
    }
  }

  &--gutter-full {
    margin-left: 20px;
    margin-right: 20px;
    grid-column-gap: 20px;
    grid-row-gap: 20px;
  }

  &--vert {
    width: 100%;
    height: 100%;
    margin-bottom: 0;
    margin-top: 0;
    grid-row-gap: 1px;
    position: absolute;
    top: 0;
    left: 0;

    &-gutter {
      grid-row-gap: 40px;
    }
  }

  &--main {
    padding-top: rem(66px);
    padding-bottom: rem(66px);

    .bg-grey--light {
      h3 {
        color: $brand-heading;
      }

      p {
        color: $brand-text;
      }
    }

    .bg-grey--light-alt {
      p {
        color: $brand-black;
        margin-top: 7px;
        margin-bottom: 33px;

        &:last-child {
          margin-top: 33px;
          margin-bottom: 0;
        }

        strong {
          @include font-weight(bold);
        }
      }
    }
  }

  &--video {
    max-width: 1094px;
    margin: 0 auto;
    padding-top: 100px;
    padding-bottom: 100px;
  }

  &__item {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: $brand-white;
    text-decoration: none;
    background-size: cover;
    background-position: center center;
    overflow: hidden;
    position: relative;
    padding-left: 5%;
    padding-right: 5%;

    .promo__zoom-bg {
      width: 100%;
      height: 100%;
      display: block;
      background-size: cover;
      background-position: center center;
      z-index: -1;
      position: absolute;
      transition: all 1s ease-in-out;
      top: 0;
      left: 0;

      &:before {
        width: 100%;
        height: 100%;
        background-color: rgba($brand-black, 0.3);
        content: "";
        position: absolute;
        top: 0;
        left: 0;
      }
    }

    &:hover:not(.promo__item--container) {
      .promo__zoom-bg {
        transform: scale(1.2);
      }
    }

    &--ratio-1-1 {
      padding-top: 100%;
    }

    &--ratio-16-6 {
      padding-top: 37.5%;
    }

    &--ratio-16-7 {
      padding-top: 46.5%;
    }

    &--ratio-16-8 {
      padding-top: 48.14%;
    }

    &--ratio-16-9 {
      padding-top: 62.25%;
    }

    &--ratio-16-11 {
      padding-top: 72.2%;
    }

    &--ratio-16-12 {
      padding-top: 75%;
    }

    &--ratio-16-13 {
      padding-top: 81.25%;
    }

    &--ratio-16-14 {
      padding-top: 87.5%;
    }

    &--ratio-16-15 {
      padding-top: 96.28%;
    }

    &--ratio-11-16 {
      padding-top: 145.45%;
    }

    &--stretch {
      height: 100%;
      padding-left: 0;
      padding-right: 0;
    }

    &--stacked {
      padding-top: 45%;
    }

    &--nav {
      @include mq($until: laptop-s) {
        padding-top: 20%;
        padding-bottom: 20%;
      }
    }

    &--text {
      padding: 10vw;

      @include mq(laptop-s) {
        padding: 5vw;
      }

      h2 {
        order: 2;
      }

      h3 {
        margin-bottom: rem(26px);
      }

      h4 {
        order: 1;
      }

      .btn {
        order: 3;
        margin-top: rem(30px);
      }
    }

    &--inner {
      width: 100%;
      display: flex;
      flex-direction: column;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      padding-left: 5%;
      padding-right: 5%;

      h4 {
        order: -1;
        margin-bottom: 10px;
      }

      .btn {
        display: inline-block;
        margin-top: rem(50px);
        align-self: center;
      }
    }

    &--link {
      cursor: pointer;
      &:hover {
        .btn {
          background-color: rgba($brand-gold, 0.1);

          &:before {
            transform: scaleX(1);
          }
        }
      }
    }

    &--showroom {
      padding: 10%;

      @include mq(mobile) {
        padding: 15%;
      }

      h3 {
        font-size: rem(20px);
        margin-bottom: rem(40px);
      }
    }
  }
}
