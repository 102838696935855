.guides {
  width: 100%;
  text-align: left;
  padding-left: 5%;
  padding-right: 5%;
  list-style: none;

  order: 3;

  .guide {
    width: 100%;
    display: flex;
    margin-top: 15px;
    margin-bottom: 18px;
    flex-direction: column;
    text-align: left;
    border-bottom: 1px solid $brand-grey-light;

    &--no-items {
      border-bottom: 0;
    }

    &__title,
    &__text {
      @include font-weight(bold);

      width: 100%;
      font-size: rem(14px);
      margin-bottom: rem(20px);
      color: $brand-black;
    }

    &__title {
      margin-top: 20px;
    }

    &:first-child {
      .guide__title {
        margin-top: 0;
      }
    }

    &__text {
      color: rgba($brand-black, 0.3);
      text-transform: none;
      margin-bottom: rem(0px);

      span {
        display: block;
        margin-right: 15px;

        &:not(:first-child) {
          margin-top: 15px;
        }
      }
    }
  }
}
