.hex-wrapper {
    display: flex;
}

.hex-container {
    width: 112px;
    margin-right: 13px;
    margin-left: 13px;
    text-decoration: none;

    @include mq ( laptop-l ) {
        width: 122px;
        margin-right: 50px;
    }

    &:last-child {
        margin-right: 0;
    }

    .hex-icon-container {
        transform: translate(-10px, -8px);
        margin-top: 8px;
    }

    .hex-icon-text {
        @include font-weight(bold);

        color: $brand-white;
        margin-top: 20px;
    }
}

.hex-icon-container {
    transform: translateY(-14px);
    position: relative;

    &--play {
        position: absolute;
        
        .svg--inner {
            width: 63px;
            height: 42px;
            top: 20px;
            left: 11px;
        }
    }

    .hex-icon {
        z-index: -1;
    }

    .svg-inline--fa {
        width: 17px;
        height: 17px;
        position: absolute;
        top: 14px;
        left: 12px;
        color: $brand-white;

        &.fa-check-circle {
            width: 19px;
            height: 20px;
            top: 13px;
            left: 11px;      
        }
    }

    .svg--inner {
        width: 20px;
        height: 20px;
        top: 13px;
        left: 10px;
        position: relative;
        z-index: 2;
        fill: $brand-white;

        &.svg--features-underfloor {
            width: 15px;
            height: 15px;
            top: 15px;
            left: 13px;
        }

        &.svg--features-care {
            width: 16px;
            height: 16px;
            top: 14px;
            left: 13px;
        }

        &.svg--features-tour {
            width: 20px;
            top: 13px;
            left: 11px;
        }
    }
}

.hex-icon {
    position: absolute;
    left: 0;
    width: 40px;
    height: 46px;
    
    &--stroke-black {
        stroke: $brand-black;
    }

    &--stroke-grey {
        stroke: $brand-grey-dark;
    }

    &--stroke-white {
        stroke: $brand-white;
    }

    &--stroke-pink {
        stroke: $brand-pink;
    }

    &--stroke-gold {
        stroke: $brand-gold;
    }

    &--stroke-green {
        stroke: $brand-green;
    }

    &--fill-black {
        fill: $brand-black;
    }

    &--fill-none {
        fill: transparent;
    }

    &--fill-white {
        fill: $brand-white;
    }

    &--fill-pink {
        fill: $brand-pink;
    }

    &--fill-gold {
        fill: $brand-gold;
    }

    &--fill-green {
        fill: $brand-green;
    }
}